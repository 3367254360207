import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import Image from 'next/image';

export const AdminNav = () => {
  const router = useRouter();
  const items = [
    {
      label: 'All Customers',
      path: '/admin/customer',
      isActive: router.pathname === '/admin/customer',
    },
    {
      label: 'All Applications',
      path: '/admin/application',
      isActive: router.pathname === '/admin/application',
    },
    {
      label: 'Tasks',
      path: '/admin/task',
      isActive: router.pathname === '/admin/task',
    },
    {
      label: 'Simulation',
      path: '/admin/simulation',
      isActive: router.pathname === '/admin/simulation',
    },
  ];
  return (
    <nav className="flex flex-row gap-4 text-black items-stretch">
      <Link href="/admin">
        <div className="flex flex-row gap-2 items-stretch leading-[1.65rem]">
          <Image src="/assets/logos/hmy-coral-logo.svg" alt="Harmoney Logo" width={24} height={24} />
          <span className="font-medium text-grey-5">Admin Portal</span>
        </div>
      </Link>
      {items.map(({ path, label, isActive }) => (
        <Link
          key={path}
          href={path}
          className={classNames(
            isActive ? 'text-secondary' : 'text-grey-5',
            'font-medium hover:text-secondary focus:text-secondary leading-lg text-sm'
          )}
        >
          {label}
        </Link>
      ))}
    </nav>
  );
};
