import { generateIcon } from '../generateIcon';

export const DashboardIcon = generateIcon({
  viewBox: '0 0 18 18',
  variant: 'none',
  path: (
    <path
      d="M16 2V4H12V2H16ZM6 2V8H2V2H6ZM16 10V16H12V10H16ZM6 14V16H2V14H6ZM18 0H10V6H18V0ZM8 0H0V10H8V0ZM18 8H10V18H18V8ZM8 12H0V18H8V12Z"
      fill="#B2B2B2"
    />
  ),
});
