import { useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetSelfEmployedTypesQuery, useGetVariablesQuery, useUploadDocumentsMutation } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  FileIcon,
  FileUpload,
  FolderIcon,
  Form,
  GraphGrowthIcon,
  useForm,
} from '@harmoney/ui-design-system';
import { DocumentCategoryEnum, SelfEmploymentTypeEnum } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { FileUploadCard } from './FileUploadCard/FileUploadCard';

interface IntroIconText {
  icon: JSX.Element;
  content: string;
}

const introIconText: IntroIconText[] = [
  {
    icon: <GraphGrowthIcon size="small" />,
    content: 'Documents from the current or previous year.',
  },
  {
    icon: <FileIcon size="small" />,
    content: 'In PDF, DOC, XLSX or CSV format.',
  },
  {
    icon: <FolderIcon size="small" />,
    content: 'We need this information to complete our review.',
  },
];
interface ExtendedFileUpload extends FileUpload {
  category?: DocumentCategoryEnum;
  incomeType: string[];
}

const fileUploadControls: ExtendedFileUpload[] = [
  {
    label: 'Tax Return or Notice of Assessment',
    helpText: 'Taxable income summary that is filed with the tax authority.',
    keyName: DocumentCategoryEnum.TAX_RETURN,
    multipleFiles: true,
    category: DocumentCategoryEnum.TAX_RETURN,
    incomeType: [
      SelfEmploymentTypeEnum.own_business,
      SelfEmploymentTypeEnum.sole_trader,
      SelfEmploymentTypeEnum.contractor,
      SelfEmploymentTypeEnum.partnership,
    ],
    showCheckBox: false,
  },

  {
    label: 'Profit and loss statement',
    helpText: 'A statement of income and expenses. ',
    keyName: DocumentCategoryEnum.PROFIT_AND_LOSS_STATEMENT,
    multipleFiles: true,
    category: DocumentCategoryEnum.PROFIT_AND_LOSS_STATEMENT,
    incomeType: [
      SelfEmploymentTypeEnum.own_business,
      SelfEmploymentTypeEnum.partnership,
      SelfEmploymentTypeEnum.sole_trader,
      SelfEmploymentTypeEnum.contractor,
    ],
    showCheckBox: true,
  },
  {
    label: 'Balance Sheet',
    helpText: 'Report of the assets, liabilities and equity.',
    keyName: DocumentCategoryEnum.BALANCE_SHEET,
    multipleFiles: true,
    category: DocumentCategoryEnum.BALANCE_SHEET,
    incomeType: [SelfEmploymentTypeEnum.own_business],
    showCheckBox: true,
  },
];

const baseSchema = z.object({
  items: z.array(
    z.object({
      documentStatus: z.boolean(),
      files: z.array(z.any()).optional(),
      category: z.enum([
        DocumentCategoryEnum.BALANCE_SHEET,
        DocumentCategoryEnum.PROFIT_AND_LOSS_STATEMENT,
        DocumentCategoryEnum.TAX_RETURN,
      ]),
    })
  ),
  errorList: z.array(z.string()).optional(),
});

export function SelfEmployedDocuments({ completeTask, taskId, taskFriendlyURL }: CommonProps) {
  const [uploadDocuments] = useUploadDocumentsMutation();
  const { data: selfEmployedTypes } = useGetSelfEmployedTypesQuery(taskId);
  const { data: variables } = useGetVariablesQuery(taskId);
  const [verification, setVerification] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasException, setHasException] = useState(false);
  const form = useForm({
    mode: 'onTouched',
    schema: baseSchema,
    defaultValues: {
      items: [],
      errorList: [],
    },
  });

  const { watch } = form;

  const handleSubmit = async () => {
    setVerification(true);
    const formData = watch();
    const files = new FormData();
    let hasFiles = false;
    let hasMissedFiles = true;
    if (formData.errorList.length === 0) {
      hasMissedFiles = false;
    }
    formData.items.forEach((item) => {
      item?.files?.forEach((file) => {
        hasFiles = true;
        files.append(item.category, file);
      });
    });
    if (!hasFiles || hasMissedFiles) {
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(true);
    files.append('loanApplicationId', variables?.loanApplicationId?.toString());
    try {
      const response = await uploadDocuments({
        files: files,
      });
      if (response['error']) {
        console.log(response['error']);
        setHasException(true);
        setIsSubmitting(false);
        return;
      }
    } catch (error) {
      console.log(error);
      setHasException(true);
      setIsSubmitting(false);
      return;
    }
    setHasException(false);
    completeTask({ taskId });
  };
  useFriendlyURL(taskFriendlyURL);
  return (
    <>
      <h1 className="text-grey-5">
        Send over your <span className="text-primary">documentation</span>
      </h1>
      <Card className="!p-0">
        <p className="mb-0 px-4 py-2 font-medium">As you are self employed, we need:</p>
        <hr className="border-grey-1" />
        {introIconText.map((introItem, index) => (
          <div key={index}>
            <div className="flex items-center gap-x-6 px-4 py-4">
              <div className="flex-none">{introItem.icon}</div>
              <p>{introItem.content}</p>
            </div>
            {index + 1 !== introIconText.length && <hr className="border-grey-1" />}
          </div>
        ))}
      </Card>
      <Form form={form} onSubmit={handleSubmit}>
        <Card className="px-4 py-4">
          <h3 className="font-bold">Self-employment documents</h3>
          <p>
            Please upload your documents for
            <span className="inline font-medium"> all self-employment income sources</span> you mentioned:
          </p>
          {fileUploadControls
            .filter((item) => item.incomeType.some((incomeType) => selfEmployedTypes?.includes(incomeType)))
            .map((filteredItem, index) => (
              <FileUploadCard
                key={`file-upload-${index}`}
                item={filteredItem}
                index={index}
                verification={verification}
              />
            ))}
          {hasException && (
            <p className="text-error leading-sm ml-2 mt-1 pb-2 text-sm">An error occurred while uploading the file</p>
          )}
        </Card>
        <Button
          alignIcon="end"
          type="submit"
          isLoading={isSubmitting}
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
