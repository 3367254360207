import { generateIcon } from '../generateIcon';

export const InfoIcon = generateIcon({
  viewBox: '0 0 16 16',
  path: (
    <g>
      <path
        fill="#206EEE"
        fillRule="nonzero"
        d="M8.8 5.6H7.2V4h1.6v1.6zm0 6.4H7.2V7.2h1.6V12zM8 0C3.581722 0 0 3.58172 0 8c0 2.12173.842855 4.15656 2.343146 5.65685C3.843437 15.15715 5.878268 16 8 16s4.156563-.84285 5.656854-2.34315C15.157145 12.15656 16 10.12173 16 8c0-2.12173-.842855-4.15656-2.343146-5.65685C12.156563.84285 10.121732 0 8 0z"
      />
    </g>
  ),
});
