import { generateIcon } from '../generateIcon';

export const BankAccountIcon = generateIcon({
  viewBox: '0 0 24 24',
  variant: 'none',
  path: (
    <>
      <g clipPath="url(#clip0_23_1409)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H8C8.55 20 9 19.55 9 19C9 18.45 8.55 18 8 18H4V12H22V6C22 4.9 21.1 4 20 4ZM20 8H4V6H20V8ZM14.93 19.17L12.81 17.05C12.42 16.66 11.79 16.66 11.4 17.05C11.01 17.44 11.01 18.07 11.4 18.46L14.23 21.29C14.62 21.68 15.25 21.68 15.64 21.29L21.3 15.63C21.69 15.24 21.69 14.61 21.3 14.22C20.91 13.83 20.28 13.83 19.89 14.22L14.93 19.17Z"
          fill="#73A4F5"
        />
        <path
          d="M14.9299 19.17L12.8099 17.05C12.4199 16.66 11.7899 16.66 11.3999 17.05C11.0099 17.44 11.0099 18.07 11.3999 18.46L14.2299 21.29C14.6199 21.68 15.2499 21.68 15.6399 21.29L21.2999 15.63C21.6899 15.24 21.6899 14.61 21.2999 14.22C20.9099 13.83 20.2799 13.83 19.8899 14.22L14.9299 19.17Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1409">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
