import { useState } from 'react';
import { QuoteOption } from '@harmoney/api-interfaces';
import { Button, Dialog, Divider, Spinner } from '@harmoney/ui-design-system';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';

import { convertMonthsToYears } from './utils';

interface QuoteSummaryV2Props {
  primaryPurpose: string;
  repaymentDetails: QuoteOption;
  isUnutilisedLimitLoan: boolean;
}

export function QuoteSummaryV2({ primaryPurpose, repaymentDetails, isUnutilisedLimitLoan }: QuoteSummaryV2Props) {
  const [isEstablishmentFeeOpen, setIsEstablishmentFeeOpenOpen] = useState(false);

  if (!repaymentDetails)
    return (
      <div className="flex flex-col items-center bg-grey-1 rounded-xl py-4">
        <Spinner size="small" />
        <p className="mb-0 mt-2">Generating your repayments...</p>
      </div>
    );

  const { establishmentFee, loanAmount, repaymentAmount, repaymentFrequency, termInMonths } = repaymentDetails;

  return (
    <>
      <div className="text-center mb-6">
        <p className="mb-0">You&rsquo;ll pay</p>
        <h2 className="mb-0">
          {formatCurrency(repaymentAmount)} / {repaymentFrequency && formatFrequency(repaymentFrequency)}
        </h2>
        <p className="mb-0 font-bold">on a {convertMonthsToYears(termInMonths)} year term</p>
      </div>

      <ul className="text-sm">
        <li className="mb-2 flex justify-between">
          <span>{primaryPurpose || 'Loan amount'}</span>
          <span className="font-medium">{formatCurrency(loanAmount)}</span>
        </li>
        <li className="flex justify-between">
          <span>{isUnutilisedLimitLoan ? 'Establishment fee' : 'One-off establishment fee'}</span>
          <span className="font-medium">{formatCurrency(establishmentFee)}</span>
        </li>
        {!isUnutilisedLimitLoan && (
          <li className="flex justify-between">
            <Button
              variant="link"
              onClick={() => setIsEstablishmentFeeOpenOpen(true)}
              className="!my-0 min-h-fit text-sm text-secondary underline"
            >
              What is this fee?
            </Button>
          </li>
        )}
        <Divider className="text-grey-2 my-2" />
        <li className="flex justify-between font-medium">
          <span>Loan total</span>
          <span>{formatCurrency(loanAmount + establishmentFee)}</span>
        </li>
      </ul>

      {!isUnutilisedLimitLoan && (
        <Dialog
          title="What is an establishment fee?"
          open={isEstablishmentFeeOpen}
          onOpenChange={() => setIsEstablishmentFeeOpenOpen(!isEstablishmentFeeOpen)}
          modal
        >
          <div className="p-4 flex flex-col">
            <p>
              To open your loan, you&rsquo;ll pay a one-time establishment fee, which is added to your loan balance. And
              that&rsquo;s it!
            </p>
            <p>So long as you stay on top of your repayments, no other fees will apply to this loan.</p>

            <Button
              variant="outline-secondary"
              onClick={() => setIsEstablishmentFeeOpenOpen(false)}
              className="my-4 self-center"
            >
              Got it
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
}
