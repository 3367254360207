import { accountTypeToDebtType, Liability, NetworthSourceDto } from '@harmoney/api-interfaces';
import { convertStringToBoolean } from '@harmoney/utilities';
import { AssetAndLiability, AssetAndLiabilityRecordOriginEnum, NetworthSource } from '@prisma/client';
import { isBoolean } from 'lodash';

export const NETWORTH_CODE_NO_DEBT = 'no_debt';
export const NETWORTH_CODE_MORTGAGE = 'mortgage';

export type DebtCode =
  | 'personal_loan'
  | 'credit_card'
  | 'overdraft'
  | 'other_debts'
  | 'mortgage'
  | 'buy_now_pay_later'
  | 'line_of_credit'
  | 'car_loan';

export const sortedDebtCode: DebtCode[] = [
  'credit_card',
  'personal_loan',
  'mortgage',
  'car_loan',
  'overdraft',
  'buy_now_pay_later',
  'other_debts',
  'line_of_credit',
];

export enum DebtTypeIcon {
  mortgage = 'ic:outline-house',
  personal_loan = 'ic:outline-money',
  credit_card = 'ic:outline-credit-card',
  fast_loan = 'ic:outline-timer',
  car_loan = 'ic:outline-directions-car',
  no_debt = 'ic:outline-block',
  overdraft = 'ic:outline-article',
  line_of_credit = 'ic:outline-restart-alt',
  other_debts = 'ic:outline-add-to-photos',
  buy_now_pay_later = 'hmy:bnpl',
}

function convertAccountTypeToProviderSuffix(accountType: string): string {
  switch (accountType) {
    case 'PF':
      return ' (Fixed)';
    case 'PR':
      return ' (Revolving)';
    default:
      return '';
  }
}

export function convertCreditFileLiabilityToDebt(liability: Liability, debtTypes: NetworthSourceDto[]) {
  const debtType = Object.keys(accountTypeToDebtType).find((debtType) =>
    accountTypeToDebtType[debtType].includes(liability.accountType)
  );
  const providerSuffix = convertAccountTypeToProviderSuffix(liability.accountType);
  let debt: object = {
    provider: `${liability.organisation}${providerSuffix}`,
    networthSourceId: debtTypes?.find((debt) => debt.code === debtType).id,
    recordOrigin: AssetAndLiabilityRecordOriginEnum.prefill,
    creditFileReference: `${liability?.accountType}_${liability?.bureauAccountId}`,
    paidOff: isBoolean(liability.paidOff) ? liability.paidOff.toString() : undefined,
    interestRate: liability.interestRate,
  };
  if (debtType === 'credit_card') {
    debt = {
      ...debt,
      creditLimit: +liability.creditLimit > 0 ? liability.creditLimit : undefined,
      interestRate: undefined,
      outstandingBalance: null,
      paysOutstandingBalance: null,
    };
  }
  if (debtType === 'mortgage') {
    debt = {
      ...debt,
      isJointMortgageFromCreditFile: isBoolean(liability?.isJointMortgage) ? liability.isJointMortgage : null,
    };
  }
  return { debt, debtType };
}

export function transformSubmittedData(data: { debts?: object }, existingOtherLoans: { id: string }[]) {
  const result = Object.entries(data.debts).flatMap(([key, value]) => {
    value.map((item) => {
      Object.entries(item).map(([key, value]) => {
        if (value === 'true' || value === 'false') {
          item[key] = convertStringToBoolean(value);
        }
        if (key === 'frequency' && value === '') {
          item[key] = null;
        }
      });
    });

    if (key === NETWORTH_CODE_MORTGAGE) {
      const data = [];
      value.map((item) => {
        if (item.jointMortgageFrequency === '') {
          item = { ...item, jointMortgageFrequency: null };
        }
        if (item.rentalIncomeCoversMortgage === '') {
          item = { ...item, rentalIncomeCoversMortgage: null };
        }
        if (item.isJointMortgage === '') {
          item = { ...item, isJointMortgage: null };
        }
        data.push(item);
      });
      return data;
    }
    if (key === NETWORTH_CODE_NO_DEBT) {
      return value[0];
    }
    return value;
  });
  const shouldDeletedIds = existingOtherLoans
    .filter(({ id }) => !isObjectArrayContainsTheId(id, result))
    .map(({ id }) => id);
  return result.concat(shouldDeletedIds.map((id) => ({ id, needDelete: true })));
}

export function isObjectArrayContainsTheId(id, array) {
  return array.find((item) => item.id === id);
}

export function convertBooleanToString(liability: AssetAndLiability & { networthSource: NetworthSource }) {
  return {
    ...liability,
    isJointMortgage: liability.isJointMortgage === null ? null : liability.isJointMortgage.toString(),
    isJointMortgageFromCreditFile:
      liability.isJointMortgageFromCreditFile === null ? null : liability.isJointMortgageFromCreditFile.toString(),
    isMortgageShared: liability.isMortgageShared === null ? null : liability.isMortgageShared.toString(),
    isResidence: liability.isResidence === null ? null : liability.isResidence.toString(),
    paidOff: liability.paidOff === null ? null : liability.paidOff.toString(),
    rentalIncomeCoversMortgage:
      liability.rentalIncomeCoversMortgage === null ? '' : liability.rentalIncomeCoversMortgage.toString(),
  };
}
