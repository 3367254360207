import { useFriendlyURL } from '@harmoney/hooks';
import { Button } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

import styles from './ConnectOption.module.scss';

/* eslint-disable-next-line */
export interface ConnectOptionProps extends CommonProps {}

export function ConnectOption({ taskId, completeTask, taskFriendlyURL }: ConnectOptionProps) {
  const handleSubmit = (userOption: string) => {
    completeTask({ taskId, variables: { userOption } });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <div className={styles['container']}>
      <h1>Welcome to ConnectOption!</h1>
      <Button variant="primary" onClick={() => handleSubmit('connect-another-bank-account')} hasShadow>
        yes, add another bank
      </Button>
      <Button variant="secondary" onClick={() => handleSubmit('continue')} hasShadow>
        no, continue
      </Button>
    </div>
  );
}

export default ConnectOption;
