import { generateIcon } from '../generateIcon';

export const StaticProgressIcon = generateIcon({
  viewBox: '0 0 16 16',
  path: (
    <>
      <circle cx="8" cy="8" r="5.8" fill="none" stroke="#d9d9d9" strokeWidth="1.5" />
      <circle
        cx="8"
        cy="8"
        r="5.8"
        fill="none"
        stroke="#206EEE"
        strokeWidth="1.5"
        strokeDasharray="16.1"
        strokeDashoffset="16.1"
      />
    </>
  ),
});
