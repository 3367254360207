import { UmiCalculationOperation } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const affordabilityApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAffordabilityByApplicationId: builder.query<UmiCalculationOperation, string>({
      query: (applicationId) => ({
        url: `/affordability`,
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    getNetIncomeByGross: builder.query<number, number>({
      query: (grossIncome) => ({
        url: '/affordability/monthly-net-income',
        method: 'GET',
        params: { grossIncome },
      }),
    }),
  }),
});

export const { useGetAffordabilityByApplicationIdQuery, useLazyGetNetIncomeByGrossQuery } = affordabilityApi;
