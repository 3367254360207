import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { errors } from '@harmoney/ui-utils';
import { isBooleanStringFalse, isBooleanStringTrue } from '@harmoney/utilities';
import { z } from 'zod';

import {
  debtSchemaBase,
  debtSchemaForBuyNowPayLater,
  debtSchemaForMortgage,
  debtSchemaForOther,
  debtSchemaForSharedMortgage,
  prefillSchema,
  providerSchemaBase,
} from './shared-config';

const debtConSchema = z.object({
  interestRate: z
    .number({
      required_error: errors.requiredField('Interest rate'),
      invalid_type_error: errors.requiredField('Interest rate'),
    })
    .nonnegative()
    .max(100)
    .min(0)
    .optional(),
});

const debtConsolidationDebtSchemaForCreditCard = z
  .intersection(
    debtSchemaBase.and(prefillSchema.partial()).and(debtConSchema),
    z.object({
      creditLimit: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional(),
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_CREDIT_CARD_ID),
      paysOutstandingBalance: z
        .string({ required_error: errors.defaultRequiredField })
        .min(1, { message: errors.defaultRequiredField })
        .nullish(),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .nullish(),
    })
  )
  .refine(
    (data) => {
      if (data.recordOrigin === 'prefill' && isBooleanStringTrue(data.paidOff)) {
        data.interestRate = null;
        return true;
      }
      if (data.paysOutstandingBalance === 'No' && data?.creditLimit < data?.repaymentAmount) {
        return false;
      }
      return true;
    },
    {
      message: errors.invalidCreditLimit,
      path: ['creditLimit'],
    }
  )
  .superRefine((data, ctx) => {
    const { addIssue } = ctx;

    if (data.recordOrigin === 'prefill') {
      if (data.paidOff === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['paidOff'],
        });
        return ctx;
      }
      if (isBooleanStringFalse(data.paidOff)) {
        if (!data.paysOutstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredField,
            path: ['paysOutstandingBalance'],
          });
        }
        if (!data.creditLimit) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['creditLimit'],
          });
        }
        if (data.paysOutstandingBalance === 'No') {
          if (!data.repaymentAmount) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.defaultValidAmount,
              path: ['repaymentAmount'],
            });
          }
          if (!data.frequency) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.defaultRequiredFrequency,
              path: ['frequency'],
            });
          }
          if (!(data.interestRate >= 0)) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.requiredField('Interest rate'),
              path: ['interestRate'],
            });
          }
          if (!data.outstandingBalance) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.defaultValidAmount,
              path: ['outstandingBalance'],
            });
          }
          return ctx;
        }
        if (isBooleanStringTrue(data.paysOutstandingBalance)) {
          data.repaymentAmount = null;
          data.frequency = null;
          data.outstandingBalance = null;
          data.interestRate = null;
        }
        return ctx;
      } else {
        data.repaymentAmount = null;
        data.frequency = null;
        data.paysOutstandingBalance = null;
        data.outstandingBalance = null;
        data.interestRate = null;
        data.creditLimit = null;
        return ctx;
      }
    }
    if (data.recordOrigin === 'manual') {
      if (data.paysOutstandingBalance === 'No') {
        if (!data.repaymentAmount) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['repaymentAmount'],
          });
        }
        if (!data.frequency) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['frequency'],
          });
        }
        if (!data.outstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['outstandingBalance'],
          });
        }
        if (!(data.interestRate >= 0)) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.requiredField('Interest rate'),
            path: ['interestRate'],
          });
        }
        return ctx;
      }
      if (isBooleanStringTrue(data.paysOutstandingBalance)) {
        data.repaymentAmount = null;
        data.frequency = null;
        data.interestRate = null;
        data.outstandingBalance = null;
        return ctx;
      }
      return ctx;
    }
  });

const debtConsolidationDebtSchemaForPersonalLoanAndCarLoan = z
  .intersection(
    debtSchemaBase.and(debtConSchema),
    z.object({
      networthSourceId: z
        .literal(NetworthSourceEnum.LIABILITY_PERSONAL_LOAN_ID)
        .or(z.literal(NetworthSourceEnum.LIABILITY_CAR_LOAN_ID)),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional(),
    })
  )
  .and(providerSchemaBase)
  .and(prefillSchema)
  .superRefine((data, ctx) => {
    const { addIssue } = ctx;

    if (data.recordOrigin === 'prefill') {
      if (data.paidOff === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['paidOff'],
        });
        return ctx;
      }
      if (isBooleanStringFalse(data.paidOff)) {
        if (!data.repaymentAmount) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['repaymentAmount'],
          });
        }
        if (!data.frequency) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['frequency'],
          });
        }
        if (!(data.interestRate >= 0)) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.requiredField('Interest rate'),
            path: ['interestRate'],
          });
        }
        if (!data.outstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['outstandingBalance'],
          });
        }
        return ctx;
      } else {
        data.repaymentAmount = null;
        data.frequency = null;
        data.outstandingBalance = null;
        data.interestRate = null;
        return ctx;
      }
    } else {
      if (!data.repaymentAmount) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['repaymentAmount'],
        });
      }
      if (!data.frequency) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredFrequency,
          path: ['frequency'],
        });
      }
      if (!(data.interestRate >= 0)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.requiredField('Interest rate'),
          path: ['interestRate'],
        });
      }
      if (!data.outstandingBalance) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['outstandingBalance'],
        });
      }
      return ctx;
    }
  });

const debtConsolidationDebtSchemaForOverdraft = z
  .intersection(
    debtSchemaBase,
    z.object({
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_OVERDRAFT_ID),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional(),
    })
  )
  .and(providerSchemaBase)
  .and(prefillSchema)
  .and(debtConSchema)
  .superRefine((data, ctx) => {
    const { addIssue } = ctx;
    if (data.recordOrigin === 'prefill') {
      if (data.paidOff === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['paidOff'],
        });
        return ctx;
      }
      if (isBooleanStringFalse(data.paidOff)) {
        if (!data.repaymentAmount) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['repaymentAmount'],
          });
        }
        if (!data.frequency) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['frequency'],
          });
        }
        if (!(data.interestRate >= 0)) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.requiredField('Interest rate'),
            path: ['interestRate'],
          });
        }
        if (!data.outstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['outstandingBalance'],
          });
        }
        return ctx;
      } else {
        data.repaymentAmount = null;
        data.frequency = null;
        data.outstandingBalance = null;
        data.interestRate = null;
        return ctx;
      }
    } else {
      if (!data.repaymentAmount) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['repaymentAmount'],
        });
      }
      if (!data.frequency) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredFrequency,
          path: ['frequency'],
        });
      }
      if (!(data.interestRate >= 0)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.requiredField('Interest rate'),
          path: ['interestRate'],
        });
      }

      if (!data.outstandingBalance) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['outstandingBalance'],
        });
      }
      return ctx;
    }
  });

const debtConsolidationDebtSchemaForLineOfCredit = z
  .intersection(
    debtSchemaBase,
    z.object({
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_LINE_OF_CREDIT_ID),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional(),
      creditLimit: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional(),
    })
  )
  .and(providerSchemaBase)
  .and(prefillSchema)
  .and(debtConSchema)
  .superRefine((data, ctx) => {
    const { addIssue } = ctx;
    if (data.recordOrigin === 'prefill') {
      if (data.paidOff === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['paidOff'],
        });
        return ctx;
      }
      if (isBooleanStringFalse(data.paidOff)) {
        if (!data.repaymentAmount) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['repaymentAmount'],
          });
        }
        if (!data.frequency) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['frequency'],
          });
        }
        if (!(data.interestRate >= 0)) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.requiredField('Interest rate'),
            path: ['interestRate'],
          });
        }
        if (!data.outstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['outstandingBalance'],
          });
        }
        if (!data.creditLimit) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
          });
        }
        return ctx;
      } else {
        data.repaymentAmount = null;
        data.frequency = null;
        data.outstandingBalance = null;
        data.interestRate = null;
        data.creditLimit = null;
        return ctx;
      }
    } else {
      if (!data.repaymentAmount) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['repaymentAmount'],
        });
      }
      if (!data.frequency) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredFrequency,
          path: ['frequency'],
        });
      }
      if (!(data.interestRate >= 0)) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.requiredField('Interest rate'),
          path: ['interestRate'],
        });
      }
      if (!data.outstandingBalance) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['outstandingBalance'],
        });
      }
      if (!data.creditLimit) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
        });
      }
      return ctx;
    }
  });

const debtConsolidationDebtSchema = (useSharedMortgage: boolean) => {
  const mortgageSchema = useSharedMortgage ? debtSchemaForSharedMortgage : debtSchemaForMortgage;
  return z.object({
    credit_card: debtConsolidationDebtSchemaForCreditCard.array().nullish(),
    mortgage: z.array(mortgageSchema).nullish(),
    personal_loan: debtConsolidationDebtSchemaForPersonalLoanAndCarLoan.array().nullish(),
    car_loan: debtConsolidationDebtSchemaForPersonalLoanAndCarLoan.array().nullish(),
    overdraft: z.array(debtConsolidationDebtSchemaForOverdraft).nullish(),
    other_debts: z.array(debtSchemaForOther).nullish(),
    buy_now_pay_later: z.array(debtSchemaForBuyNowPayLater).nullish(),
    line_of_credit: z.array(debtConsolidationDebtSchemaForLineOfCredit).nullish(),
  });
};

export const debtConsolidationRootSchema = (useSharedMortgage: boolean) =>
  z.object({
    debts: debtConsolidationDebtSchema(useSharedMortgage),
  });
