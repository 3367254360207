import { generateIcon } from '../generateIcon';

export const SavingIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_6114_51853)">
        <path
          d="M15 10C15 9.45 15.45 9 16 9C16.55 9 17 9.45 17 10C17 10.55 16.55 11 16 11C15.45 11 15 10.55 15 10ZM8 9H13V7H8V9ZM22 7.5V14.47L19.18 15.41L17.5 21H12V19H10V21H4.5C4.5 21 2 12.54 2 9.5C2 6.46 4.46 4 7.5 4H12.5C13.41 2.79 14.86 2 16.5 2C17.33 2 18 2.67 18 3.5C18 3.71 17.96 3.9 17.88 4.08C17.74 4.42 17.62 4.81 17.56 5.23L19.83 7.5H22ZM20 9.5H19L15.5 6C15.5 5.35 15.59 4.71 15.76 4.09C14.79 4.34 14 5.06 13.67 6H7.5C5.57 6 4 7.57 4 9.5C4 11.38 5.22 16.15 6.01 19H8V17H14V19H16.01L17.56 13.85L20 13.03V9.5Z"
          fill="#73A4F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6114_51853">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
