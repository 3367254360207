import { generateIcon } from '../generateIcon';

export const GoogleIcon = generateIcon({
  viewBox: '0 0 498.648 498.648',
  path: (
    <>
      <path
        fill="#ff7976"
        d="M254.956 0C179.332 0 111.908 33.168 66.228 85.752c-13.92 16.04-25.848 33.856-35.336 53.128l84.008 64.168c9.736-29.544 28.536-54.896 53.12-72.832C192.364 112.48 222.38 102 254.948 102c35.184 0 66.96 12.472 91.928 32.872l72.632-72.528C375.26 23.8 318.516 0 254.956 0z"
      />
      <path
        fill="#e3443a"
        d="m30.9 138.872 84.008 64.168c9.736-29.544 28.536-54.896 53.12-72.832 24.344-17.736 54.36-28.216 86.928-28.216 35.184 0 66.96 12.472 91.928 32.872l72.632-72.528C375.252 23.808 318.516 0 254.956 0"
      />
      <path
        fill="#f4d72c"
        d="M107.428 249.32c0-2.296.168-4.56.272-6.832.656-13.728 3.096-26.96 7.208-39.448L30.9 138.872C15.452 170.208 6.492 205.296 5.484 242.488c-.064 2.28-.2 4.544-.2 6.832 0 2.296.136 4.568.192 6.848 1.008 37.144 9.968 72.216 25.376 103.52l83.984-64.288c-4.072-12.424-6.496-25.6-7.136-39.24-.104-2.272-.272-4.536-.272-6.84z"
      />
      <path
        fill="#f7b92b"
        d="m30.86 359.688 83.984-64.288c-4.072-12.424-6.496-25.6-7.136-39.24-.104-2.28-.272-4.544-.272-6.848 0-2.296.168-4.56.272-6.832.656-13.728 3.096-26.96 7.208-39.448L30.9 138.872"
      />
      <path
        fill="#59c96e"
        d="M337.876 374.848c-22.504 14.176-50.848 21.8-82.92 21.8-18.768 0-36.648-3.568-53.128-9.912-12.136-4.672-23.464-10.92-33.808-18.44-24.576-17.896-43.384-43.168-53.12-72.688l-84.008 64.16c9.472 19.24 21.376 37.032 35.272 53.048 45.68 52.64 113.152 85.832 188.792 85.832 22.88 0 45.368-3.056 66.84-9.008 35.696-9.904 68.592-27.832 95.832-53.128l-79.752-61.664z"
      />
      <path
        fill="#40a557"
        d="M30.9 359.768c9.472 19.24 21.376 37.032 35.272 53.048 45.68 52.64 113.152 85.832 188.792 85.832 22.88 0 45.368-3.056 66.84-9.008 35.696-9.904 68.592-27.832 95.832-53.128l-79.76-61.656c-22.504 14.176-50.848 21.8-82.92 21.8-18.768 0-36.648-3.568-53.128-9.912-12.136-4.672-23.464-10.92-33.808-18.44"
      />
      <path
        fill="#0faef4"
        d="M487.612 201.32H257.284v96h131.592c-6.696 32-24.92 61.088-51 77.528l79.76 61.656c21.872-20.304 40.04-45.784 53.112-75.256 14.336-32.288 22.536-70.96 22.536-113.456 0-14.72-2.264-30.472-5.672-46.472z"
      />
      <g fill="#4087ed">
        <path d="M417.636 436.504c21.872-20.304 40.2-46.072 53.272-75.544 14.336-32.288 22.456-70.816 22.456-113.304 0-14.728-2.344-30.336-5.752-46.336h-14.328M257.284 241.32v56h128" />
      </g>
    </>
  ),
});
