import { generateIcon } from '../generateIcon';

export const BankStatementIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_2490_20089)">
        <path
          d="M14 1H2C0.9 1 0 1.9 0 3V17H2V3H14V1ZM17 5H6C4.9 5 4 5.9 4 7V21C4 22.1 4.9 23 6 23H17C18.1 23 19 22.1 19 21V7C19 5.9 18.1 5 17 5ZM17 21H6V7H17V21Z"
          fill="#73A4F5"
        />
        <path
          d="M22.78 12.855L24 14.25L22.78 15.645L22.95 17.49L21.145 17.9L20.2 19.495L18.5 18.765L16.8 19.5L15.855 17.905L14.05 17.495L14.22 15.645L13 14.25L14.22 12.85L14.05 11L15.855 10.595L16.8 9L18.5 9.73L20.2 9L21.145 10.6L22.95 11.01L22.78 12.855Z"
          fill="white"
        />
        <path
          d="M24 14.25L22.78 12.855L22.95 11.01L21.145 10.6L20.2 9L18.5 9.73L16.8 9L15.855 10.595L14.05 11L14.22 12.85L13 14.25L14.22 15.645L14.05 17.495L15.855 17.905L16.8 19.5L18.5 18.765L20.2 19.495L21.145 17.9L22.95 17.49L22.78 15.645L24 14.25ZM17.545 16.61L15.645 14.705L16.385 13.965L17.545 15.13L20.47 12.195L21.21 12.935L17.545 16.61Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2490_20089">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
