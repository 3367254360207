import { generateIcon } from '../generateIcon';

export const CheckCircleIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <circle cx="12" cy="12" r="12" fill="#45D06C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97108 17.2199C9.62152 17.2234 9.27085 17.0914 9.00413 16.8238L5.19112 12.9976C4.66465 12.4694 4.66465 11.6129 5.19112 11.0846C5.71758 10.5563 6.57116 10.5563 7.09762 11.0846L9.95894 13.9557L15.9978 7.89615C16.5243 7.36787 17.3778 7.36787 17.9043 7.89614C18.4308 8.42442 18.4308 9.28092 17.9043 9.8092L10.9138 16.8237C10.6532 17.0852 10.3126 17.2172 9.97108 17.2199Z"
        fill="white"
      />
    </>
  ),
});
