export const HomeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.2327 16.7271L20.4631 1.39186L19.4061 0.363427C19.1653 0.130646 18.8396 0 18.5001 0C18.1606 0 17.8349 0.130646 17.5941 0.363427L0.767427 16.7271C0.520644 16.9662 0.325607 17.2511 0.193829 17.5648C0.0620508 17.8785 -0.00379804 18.2147 0.000169163 18.5536C0.0164938 19.9513 1.21227 21.0671 2.64884 21.0671H4.38334V34H32.6168V21.0671H34.388C35.0859 21.0671 35.743 20.8011 36.2368 20.3206C36.48 20.0848 36.6726 19.8044 36.8036 19.4956C36.9346 19.1869 37.0014 18.8559 37 18.5219C37 17.8468 36.7265 17.2075 36.2327 16.7271ZM20.7855 31.141H16.2146V23.0406H20.7855V31.141ZM29.6784 18.2082V31.141H23.3975V22.0876C23.3975 21.2101 22.6669 20.4993 21.765 20.4993H15.2351C14.3332 20.4993 13.6027 21.2101 13.6027 22.0876V31.141H7.32177V18.2082H3.40386L18.5042 3.52815L19.4469 4.4454L33.6004 18.2082H29.6784Z"
        fill="#FF4B4A"
      />
    </svg>
  );
};
