import { useEffect, useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetUserQuery, useSendVerificationEmailQuery } from '@harmoney/redux';
import { Alert, ArrowCircleRightIcon, Button, Card, EmailVerificationIcon, Spinner } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

const timeoutMillisecond =
  +process.env['NEXT_PUBLIC_EMAIL_VERIFICATION_SUCCESSFUL_SCREEN_DISPLAY_DURATION_IN_MILLISECONDS'];

export function EmailVerification({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [isSuccessfulAlertVisible, setIsSuccessfulAlertVisible] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: userData, isLoading } = useGetUserQuery(null, { refetchOnMountOrArgChange: true });
  const { isSuccess, refetch } = useSendVerificationEmailQuery();
  const handleEmailChange = () => {
    if (isProcessing) return;
    setIsProcessing(true);
    completeTask({ taskId, variables: { isEmailToBeUpdated: true } });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSuccessfulAlertVisible(false);
    }, timeoutMillisecond);

    return () => clearTimeout(timeout);
  }, []);

  useFriendlyURL(taskFriendlyURL);

  if (isLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <h1>
        Let&rsquo;s confirm your <span className="text-primary">email</span>
      </h1>

      {isSuccess && isSuccessfulAlertVisible && (
        <Alert variant="success" title="Verification email sent" className="my-6">
          <p className="text-sm">Please check your email inbox for further instructions.</p>
        </Alert>
      )}

      <Card>
        <EmailVerificationIcon width="180" height="120" className="mb-6" />
        <Alert className="mb-4 whitespace-normal break-all" variant="wait" title="Verify your email to continue">
          <p className="text-sm">
            Please click the link sent to your email <br />
            <span className="font-medium">{userData?.email}</span>{' '}
          </p>
          <p className="text-sm">You&rsquo;ll then be automatically redirected to the next step.</p>
        </Alert>
        <div>
          <p className="mb-0 text-sm">Can&rsquo;t find the email?</p>
          <ul className="pl-4 text-sm">
            <li className="list-disc">
              <span>Check your spam folder</span>
            </li>

            <li className="list-disc">
              <Button
                as="a"
                variant="link"
                className="text-secondary border-none text-sm h-0 min-h-0"
                onClick={handleEmailChange}
                disabled={isProcessing}
              >
                Change your email
              </Button>{' '}
              if you entered it incorrectly
            </li>
          </ul>
        </div>
      </Card>

      <div className="text-center">
        <Button
          onClick={refetch}
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          hasShadow
        >
          Resend Email
        </Button>
      </div>
    </>
  );
}
