import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { AmountFrequency, CommonOptionProps, Select, Textarea, ToggleGroup } from '@harmoney/ui-design-system';
import { CO_MIN_RENT_OVERRIDE, errors, toggleYesNoOptions } from '@harmoney/ui-utils';
import { convertToMonthlyAmount, frequencyOptionsWithYear, isBooleanStringTrue } from '@harmoney/utilities';
import { z } from 'zod';

const ACCOMMODATION_EXPENSES_CODES = ['rent', 'boarder', 'live_with_parents', 'government_housing', 'company_housing'];

const baseItemSchemaIncome = z.object({
  itemId: z.string(),
  overrideAmount: z.coerce.number(),
  overrideFrequency: z.string(),
  overrideReason: z
    .string({ required_error: errors.defaultRequiredField })
    .min(1, { message: errors.defaultRequiredField }),
  overrideReasonForOther: z.string().optional(),
});

const baseItemSchemaExpense = z.object({
  itemId: z.string(),
  networthSourceCode: z.string(),
  overrideAmount: z.coerce
    .number({ invalid_type_error: errors.defaultValidAmount })
    .gte(0, { message: errors.defaultValidAmount }),
  overrideFrequency: z.string(),
  overrideReason: z
    .string({ required_error: errors.defaultRequiredField })
    .min(1, { message: errors.defaultRequiredField }),
  overrideReasonForOther: z.string().optional(),
  overrideIsExpenseShared: z
    .string({ required_error: errors.defaultRequiredField })
    .min(1, { message: errors.defaultRequiredField })
    .optional(),
});

const refine = (data, { addIssue }) => {
  if (data.overrideReason === 'other' && !data.overrideReasonForOther) {
    addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Please provide details',
      path: ['overrideReasonForOther'],
    });
  }
  if (!data.overrideFrequency) {
    addIssue({
      code: z.ZodIssueCode.custom,
      message: errors.defaultRequiredFrequency,
      path: ['overrideFrequency'],
    });
  }
  if (ACCOMMODATION_EXPENSES_CODES.includes(data.networthSourceCode)) {
    const rent = convertToMonthlyAmount(data.overrideAmount, data.overrideFrequency);
    if (data.overrideAmount === 0 || rent >= CO_MIN_RENT_OVERRIDE) {
      return;
    } else {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter 0 or a minimum of $${CO_MIN_RENT_OVERRIDE} per month`,
        path: ['overrideAmount'],
      });
    }
  }
};

export const sharedOverrideFormSchema = z.object({
  overrideItem: z.array(
    baseItemSchemaExpense
      .extend({
        overrideTotalAmount: z.coerce
          .number({ invalid_type_error: errors.defaultValidAmount })
          .gte(0, { message: errors.defaultValidAmount })
          .optional(),
        overrideTotalFrequency: z.string().optional(),
      })
      .superRefine((data, { addIssue }) => {
        refine(data, { addIssue });
        if (!data.overrideTotalFrequency && isBooleanStringTrue(data.overrideIsExpenseShared)) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['overrideTotalFrequency'],
          });
        }
      })
  ),
});

export const nonSharedOverrideIncomeFormSchema = z.object({
  overrideItem: z.array(baseItemSchemaIncome.superRefine(refine)),
});

export const nonSharedOverrideExpenseFormSchema = z.object({
  overrideItem: z.array(baseItemSchemaExpense.superRefine(refine)),
});

export type SharedOverrideFormSchemaType = z.infer<typeof sharedOverrideFormSchema>;
export type NonSharedOverrideIncomeFormSchemaType = z.infer<typeof nonSharedOverrideIncomeFormSchema>;
export type NonSharedOverrideExpenseFormSchemaType = z.infer<typeof nonSharedOverrideExpenseFormSchema>;

const NON_SHAREABLE_EXPENSES_CODES = ['pay_child_support', ...ACCOMMODATION_EXPENSES_CODES];

export const OverrideForm = ({
  overrideOptions,
  index,
  field,
  isShared = false,
  type,
  networthSourceCode,
  amountLabel = 'Override amount:',
}: {
  type: 'income' | 'expense';
  index: number;
  overrideOptions: CommonOptionProps[];
  field:
    | FieldArrayWithId<NonSharedOverrideIncomeFormSchemaType>
    | FieldArrayWithId<NonSharedOverrideExpenseFormSchemaType>
    | FieldArrayWithId<SharedOverrideFormSchemaType>;
  isShared?: boolean;
  networthSourceCode?: string;
  amountLabel?: string;
}) => {
  const { register, watch, resetField } = useFormContext();

  const canOverrideShared = type === 'expense' && !NON_SHAREABLE_EXPENSES_CODES.includes(networthSourceCode);

  return (
    <div className="gap-2 pt-2 flex flex-col" key={field?.id}>
      {type === 'expense' && !isShared && canOverrideShared && (
        <ToggleGroup
          {...register(`overrideItem[${index}].overrideIsExpenseShared` as const)}
          options={toggleYesNoOptions}
          label="Is this expense shared?"
        />
      )}
      {(watch(`overrideItem[${index}].overrideIsExpenseShared`) === 'Yes' || (isShared && canOverrideShared)) && (
        <AmountFrequency
          label="Override Total amount:"
          register={register}
          options={frequencyOptionsWithYear}
          name="overrideItem"
          inputKey={`overrideItem[${index}].overrideTotalAmount` as const}
          selectKey={`overrideItem[${index}].overrideTotalFrequency` as const}
        />
      )}
      <AmountFrequency
        label={
          ACCOMMODATION_EXPENSES_CODES.includes(networthSourceCode)
            ? 'Customer actually pays'
            : isShared || watch(`overrideItem[${index}].overrideIsExpenseShared`) === 'Yes'
              ? "Override customer's share"
              : amountLabel
        }
        register={register}
        options={frequencyOptionsWithYear}
        name="overrideItem"
        inputKey={`overrideItem[${index}].overrideAmount` as const}
        selectKey={`overrideItem[${index}].overrideFrequency` as const}
      />
      <div className="flex flex-col gap-2">
        <Select
          label="Override reason"
          options={overrideOptions}
          {...register(`overrideItem[${index}].overrideReason`, {
            onChange: (e) =>
              e.target.value !== 'other' && resetField(`overrideItem[${index}].overrideReasonForOther` as const),
          })}
        />
        {watch(`overrideItem[${index}].overrideReason`) === 'other' && (
          <Textarea {...register(`overrideItem[${index}].overrideReasonForOther` as const)} placeholder="Add notes" />
        )}
      </div>
    </div>
  );
};
