import { generateIcon } from '../generateIcon';

export const FaceUnlockIcon = generateIcon({
  viewBox: '0 0 60 60',
  path: (
    <>
      <g clipPath="url(#clip0_25_226)">
        <path
          d="M25.625 32.5C25.625 34.225 24.225 35.625 22.5 35.625C20.775 35.625 19.375 34.225 19.375 32.5C19.375 30.775 20.775 29.375 22.5 29.375C24.225 29.375 25.625 30.775 25.625 32.5ZM37.5 29.375C35.775 29.375 34.375 30.775 34.375 32.5C34.375 34.225 35.775 35.625 37.5 35.625C39.225 35.625 40.625 34.225 40.625 32.5C40.625 30.775 39.225 29.375 37.5 29.375ZM55 30C55 43.8 43.8 55 30 55C16.2 55 5 43.8 5 30C5 16.2 16.2 5 30 5C43.8 5 55 16.2 55 30ZM26.65 10.3C30.15 16.1 36.5 20 43.75 20C44.9 20 46.025 19.875 47.1 19.7C43.6 13.9 37.25 10 30 10C28.85 10 27.725 10.125 26.65 10.3ZM11.05 23.675C15.325 21.25 18.625 17.3 20.2 12.575C15.925 15 12.625 18.95 11.05 23.675ZM50 30C50 28.05 49.7 26.175 49.175 24.4C47.425 24.775 45.625 25 43.75 25C35.925 25 28.95 21.4 24.35 15.775C21.725 22.175 16.5 27.2 10 29.65C10.025 29.75 10 29.875 10 30C10 41.025 18.975 50 30 50C41.025 50 50 41.025 50 30Z"
          fill="url(#paint0_linear_25_226)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_25_226" x1="55" y1="30" x2="5" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF4B4A" />
          <stop offset="1" stopColor="#FF8D8C" />
        </linearGradient>
        <clipPath id="clip0_25_226">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
