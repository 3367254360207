import { generateIcon } from '../generateIcon';

export const EmptyProgressIcon = generateIcon({
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#clip0_400_24421)">
        <path
          d="M8.00065 1.83337C4.32065 1.83337 1.33398 4.82004 1.33398 8.50004C1.33398 12.18 4.32065 15.1667 8.00065 15.1667C11.6807 15.1667 14.6673 12.18 14.6673 8.50004C14.6673 4.82004 11.6807 1.83337 8.00065 1.83337ZM8.00065 13.8334C5.05398 13.8334 2.66732 11.4467 2.66732 8.50004C2.66732 5.55337 5.05398 3.16671 8.00065 3.16671C10.9473 3.16671 13.334 5.55337 13.334 8.50004C13.334 11.4467 10.9473 13.8334 8.00065 13.8334Z"
          fill="#DBDBDB"
        />
      </g>
      <defs>
        <clipPath id="clip0_400_24421">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});
