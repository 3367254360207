import { generateIcon } from '../generateIcon';

export const EmailIcon = generateIcon({
  viewBox: '0 0 24 25',
  path: (
    <>
      <g clipPath="url(#clip0_308_6901)">
        <path
          d="M20 4.92627H4C2.9 4.92627 2.01 5.82627 2.01 6.92627L2 18.9263C2 20.0263 2.9 20.9263 4 20.9263H20C21.1 20.9263 22 20.0263 22 18.9263V6.92627C22 5.82627 21.1 4.92627 20 4.92627ZM20 18.9263H4V8.92627L12 13.9263L20 8.92627V18.9263ZM12 11.9263L4 6.92627H20L12 11.9263Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_6901">
          <rect width="24" height="24" fill="white" transform="translate(0 0.92627)" />
        </clipPath>
      </defs>
    </>
  ),
});
