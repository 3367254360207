import { generateIcon } from '../generateIcon';

export const ValidIdIcon = generateIcon({
  viewBox: '0 0 24 24',
  variant: 'none',
  path: (
    <>
      <g clipPath="url(#clip0_23_1408)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1 8C10.1 6.84 9.16 5.9 8 5.9C6.84 5.9 5.9 6.84 5.9 8C5.9 9.16 6.84 10.1 8 10.1C9.16 10.1 10.1 9.16 10.1 8ZM10.5013 15.2028C9.70524 15.0185 8.84613 14.9 8 14.9C5.03 14.9 1.9 16.36 1.9 17V18.1H10.0324C10.1059 18.7779 10.3026 19.4189 10.5997 20H1C0.45 20 0 19.55 0 19V17C0 14.34 5.33 13 8 13C8.9922 13 10.3517 13.185 11.6686 13.5541C11.1846 14.0241 10.7869 14.5823 10.5013 15.2028ZM4 8C4 5.79 5.79 4 8 4C10.21 4 12 5.79 12 8C12 10.21 10.21 12 8 12C5.79 12 4 10.21 4 8Z"
          fill="#73A4F5"
        />
        <path
          d="M15.8225 18.2425L13.7025 16.1225C13.3125 15.7325 12.6825 15.7325 12.2925 16.1225C11.9025 16.5125 11.9025 17.1425 12.2925 17.5325L15.1225 20.3625C15.5125 20.7525 16.1425 20.7525 16.5325 20.3625L22.1925 14.7025C22.5825 14.3125 22.5825 13.6825 22.1925 13.2925C21.8025 12.9025 21.1725 12.9025 20.7825 13.2925L15.8225 18.2425Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1408">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
