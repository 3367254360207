import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { useBranch } from '@harmoney/hooks';
import { useAppSelector, useGetUserQuery } from '@harmoney/redux';
import { Footer, Header } from '@harmoney/ui-app-shell';

import { Search } from './admin/Search/Search';

interface LayoutProps {
  children?: ReactNode;
  title?: string;
  withBreadcrumbs?: boolean;
  withHeader?: boolean;
  overrideLastCrumb?: boolean;
}

export const Layout = ({ children, title = 'Harmoney', withHeader = true }: LayoutProps) => {
  const { pathname } = useRouter();
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const { data: user } = useGetUserQuery(null, { refetchOnMountOrArgChange: true, skip: !accessToken });

  const isAdmin = pathname.startsWith('/admin');
  const branch = useBranch();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{title}</title>
        <meta name="description" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={title} />
        <meta property="og:site_name" content={title} />
      </Head>
      <div className="flex min-h-screen flex-col bg-gradient-grey">
        {withHeader && <Header isAdmin={isAdmin} user={user} search={<Search />} />}
        {children}
        {!isAdmin && <Footer branch={branch} />}
      </div>
    </>
  );
};
