import * as RadixDialog from '@radix-ui/react-dialog';
import classNames from 'classnames';

import { Button } from '../Button';
import { CloseIcon } from '../Icon';

import styles from './Dialog.module.scss';

export interface DialogProps {
  /**
   * The open state of the dialog when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * The controlled open state of the dialog. Must be used in conjunction with `onOpenChange`.
   */
  open?: boolean;
  /**
   * Event handler called when the open state of the dialog changes.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * The modality of the dialog. When set to `true`, interaction with outside elements will be disabled and only dialog content will be visible to screen readers.
   * @default false
   */
  modal?: boolean;
  /**
   * The title of the dialog.
   */
  title?: string;
  children?: React.ReactNode;
  /**
   * If show close button
   */
  showCloseButton?: boolean;
  contentClassName?: string;
}

export const Dialog = ({
  children,
  title,
  onOpenChange,
  open,
  defaultOpen,
  modal = false,
  showCloseButton = true,
  contentClassName,
  ...props
}: DialogProps) => (
  <RadixDialog.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange} {...props}>
    <RadixDialog.Portal>
      <RadixDialog.Overlay className={styles['overlay']} />
      <RadixDialog.Content
        className={classNames(styles['content'], contentClassName)}
        onInteractOutside={(e) => {
          modal && e.preventDefault();
        }}
      >
        {title && (
          <div className="border-b-grey-1 flex items-center justify-between border-b p-4">
            <h2 className="mb-0 text-lg font-medium">{title}</h2>
            {showCloseButton && (
              <RadixDialog.Close asChild>
                <Button icon={<CloseIcon size="tiny" />} aria-label="Close" />
              </RadixDialog.Close>
            )}
          </div>
        )}
        {children}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  </RadixDialog.Root>
);
