import { generateIcon } from '../generateIcon';

export const MobileFriendlyIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_426_3333)">
        <path
          d="M18.9999 1H8.99994C7.89994 1 6.99994 1.9 6.99994 3V6H8.99994V4H18.9999V20H8.99994V18H6.99994V21C6.99994 22.1 7.89994 23 8.99994 23H18.9999C20.0999 23 20.9999 22.1 20.9999 21V3C20.9999 1.9 20.0999 1 18.9999 1ZM7.00994 13.47L4.45994 10.92L3.18994 12.19L6.99994 16L14.1899 8.81L12.9199 7.54L7.00994 13.47Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_426_3333">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
