import React from 'react';
import classNames from 'classnames';

import styles from './Badge.module.scss';

export interface BadgeProps {
  label: string;
  variant?: 'success' | 'secondary' | 'tertiary' | 'action' | 'warning';
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({ label, variant = 'success', className }) => {
  const classes = classNames(
    [styles['badge']],
    {
      [styles[`badge-${variant}`]]: variant,
    },
    className
  );
  return <div className={classes}>{label}</div>;
};
