import { generateIcon } from '../generateIcon';

export const ArrowCircleRightGradientIcon = generateIcon({
  viewBox: '0 0 32 32',
  path: (
    <>
      <g clipPath="url(#clip0_3037_722)">
        <path
          d="M0 16C7.72516e-07 7.16344 7.16345 -7.72516e-07 16 0C24.8366 7.72516e-07 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 -7.72516e-07 24.8366 0 16Z"
          fill="url(#paint0_linear_3037_722)"
        />
        <path
          className="animate-swing"
          d="M22 15.6L16.4 10M22 15.6L16.4 21.2M22 15.6H6"
          stroke="white"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3037_722"
          x1="32"
          y1="16"
          x2="-2.08616e-07"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4B4A" />
          <stop offset="1" stopColor="#FF8D8C" />
        </linearGradient>
        <clipPath id="clip0_3037_722">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
