import { generateIcon } from '../generateIcon';

export const ChecklistIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_6114_51841)">
        <path
          d="M11 6.99969H2V8.99969H11V6.99969ZM11 14.9997H2V16.9997H11V14.9997ZM16.34 10.9997L12.8 7.45969L14.21 6.04969L16.33 8.16969L20.57 3.92969L22 5.33969L16.34 10.9997ZM16.34 18.9997L12.8 15.4597L14.21 14.0497L16.33 16.1697L20.57 11.9297L22 13.3397L16.34 18.9997Z"
          fill="#73A4F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6114_51841">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
