import { generateIcon } from '../generateIcon';

export const NeedHelpIcon = generateIcon({
  viewBox: '0 0 20 20',
  variant: 'none',
  path: (
    <path
      d="M13 2V9H3.17L2.58 9.59L2 10.17V2H13ZM14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10V1C15 0.45 14.55 0 14 0ZM19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4Z"
      fill="#B2B2B2"
    />
  ),
});
