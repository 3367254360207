import { generateIcon } from '../generateIcon';

export const GraphGrowthIcon = generateIcon({
  viewBox: '0 0 19 20',
  path: (
    <path
      d="M5 11.65V4H8V11.65L6.5 10.25L5 11.65ZM10 13.15V0H13V10.15L10 13.15ZM0 16.6V8H3V13.6L0 16.6ZM0 19.05L6.45 12.6L10 15.65L15.6 10.05H14V8.05H19V13.05H17V11.45L10.1 18.35L6.55 15.3L2.8 19.05H0Z"
      fill="#73A4F5"
    />
  ),
});
