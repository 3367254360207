import { generateIcon } from '../generateIcon';

export const NoAccountChangesIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g id="content_copy_black_24dp 3" clipPath="url(#clip0_7795_11585)">
        <rect width="24" height="24" fill="white" />
        <path
          id="Vector"
          d="M14 1H2C0.9 1 0 1.9 0 3V17H2V3H14V1ZM17 5H6C4.9 5 4 5.9 4 7V21C4 22.1 4.9 23 6 23H17C18.1 23 19 22.1 19 21V7C19 5.9 18.1 5 17 5ZM17 21H6V7H17V21Z"
          fill="#73A4F5"
        />
        <ellipse id="Ellipse 48" cx="18.5" cy="13.5" rx="6.5" ry="5.5" fill="white" />
        <rect id="Rectangle 48" x="15" y="18" width="7" height="3" fill="white" />
        <path
          id="Vector_2"
          d="M18.8636 13.6677L17.601 12.3952V9.63628C17.601 9.28505 17.8838 9 18.2323 9C18.5808 9 18.8636 9.28505 18.8636 9.63628V13.6677ZM22.399 14.981V14.0902V11.1634C22.399 10.8121 22.1162 10.5271 21.7677 10.5271C21.4192 10.5271 21.1364 10.8121 21.1364 11.1634V14.0902H20.6313V10.1453C20.6313 9.79408 20.3485 9.50902 20 9.50902C19.6515 9.50902 19.3687 9.79408 19.3687 10.1453V14.1768L22.399 17.2309V14.981ZM17.096 10.6543C17.096 10.3031 16.8131 10.018 16.4646 10.018C16.1263 10.018 15.8586 10.2878 15.8384 10.6187L17.096 11.8862V10.6543ZM18.8636 13.6677L17.601 12.3952V9.63628C17.601 9.28505 17.8838 9 18.2323 9C18.5808 9 18.8636 9.28505 18.8636 9.63628V13.6677ZM22.399 14.981V14.0902V11.1634C22.399 10.8121 22.1162 10.5271 21.7677 10.5271C21.4192 10.5271 21.1364 10.8121 21.1364 11.1634V14.0902H20.6313V10.1453C20.6313 9.79408 20.3485 9.50902 20 9.50902C19.6515 9.50902 19.3687 9.79408 19.3687 10.1453V14.1768L22.399 17.2309V14.981ZM17.096 10.6543C17.096 10.3031 16.8131 10.018 16.4646 10.018C16.1263 10.018 15.8586 10.2878 15.8384 10.6187L17.096 11.8862V10.6543ZM23 19.2772L13.7172 9.92133L13 10.6391L15.8434 13.5049L15.8333 13.4947V15.6835C15.2727 15.3577 14.5303 14.9352 14.5202 14.9301C14.4343 14.8843 14.3485 14.8589 14.2475 14.8589C14.1162 14.8589 13.9949 14.9047 13.8939 14.9912C13.8737 14.9963 13.3081 15.5562 13.3081 15.5562L16.7424 19.211C17.0303 19.5164 17.4242 19.6895 17.8434 19.6895H20.8838C21.197 19.6895 21.4798 19.5928 21.7172 19.4248L21.7071 19.4146L22.2879 20L23 19.2772Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_7795_11585">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
