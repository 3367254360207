import Image from 'next/image';
import { useMemo, useState } from 'react';
import { NetworthSourceEnum, NetworthSourceKind } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useAppSelector, useGetNetworthSourcesQuery, useSubmitAssetMutation } from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_ASSETS_PROVIDED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Divider, Form, Label, useForm } from '@harmoney/ui-design-system';
import { CollapsibleHeader } from '@harmoney/ui-design-system';
import { capitalizeTitle, errors } from '@harmoney/ui-utils';
import { every, isEmpty, kebabCase, map, slice } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { AssetItem } from './AssetItem/AssetItem';
import { initialFormValues } from './InitialFormValues';

const NETWORTH_CODE_NO_ASSETS = 'no_assets';

const AssetSchemaBase = z.object({
  declaredAmount: z.coerce
    .number({ invalid_type_error: errors.defaultValidAmount })
    .gte(1, { message: errors.defaultValidAmount }),
});
const AssetSchemaForNotListed = AssetSchemaBase.extend({
  networthSourceId: z.literal(NetworthSourceEnum.ASSET_OTHER_ID),
  assetName: z.string().min(1, { message: errors.requiredField('Asset name') }),
});

const AssetSchemaForOthers = AssetSchemaBase.extend({
  networthSourceId: z
    .literal(NetworthSourceEnum.ASSET_PROPERTY_LAND_ID)
    .or(z.literal(NetworthSourceEnum.ASSET_VEHICLE_ID))
    .or(z.literal(NetworthSourceEnum.ASSET_SAVINGS_ID))
    .or(z.literal(NetworthSourceEnum.ASSET_SHARES_BONDS_FUNDS_ID))
    .or(z.literal(NetworthSourceEnum.ASSET_BOAT_ID))
    .or(z.literal(NetworthSourceEnum.ASSET_NO_ID)),
});

const assetSchema = z.union([AssetSchemaForNotListed, AssetSchemaForOthers]);

const rootSchema = z.object({
  assets: z.record(z.array(assetSchema)),
});

export function Asset({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const userId = useAppSelector((state) => state.userId.value);
  const form = useForm({
    mode: 'onTouched',
    schema: rootSchema,
    defaultValues: {
      assets: {},
    },
  });
  const { register, watch, setValue, getValues, reset, resetField } = form;

  const { data: assetTypes } = useGetNetworthSourcesQuery(NetworthSourceKind.ASSET);
  const [submitAsset] = useSubmitAssetMutation();

  const watchForm = watch();
  const watchAssets = watchForm.assets;

  const [formTouched, setFormTouched] = useState(false);
  const [otherAssetOpen, setOtherAssetOpen] = useState(false);
  const [noAssetData, setNoAssetData] = useState(null);
  const [disableCollapsible, setDisableCollapsible] = useState(false);
  const [primaryAssetTypes, setPrimaryAssetTypes] = useState([]);
  const [secondaryAssetTypes, setSecondaryAssetTypes] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const handleSubmit = async (data) => {
    data['assets'] = every(data['assets'], (value) => isEmpty(value)) ? noAssetData : data['assets'];
    if (isEmpty(data.assets)) {
      setFormSubmitting(false);

      setFormTouched(true);
      return;
    }

    setFormSubmitting(true);

    const transformedData = Object.entries(data.assets).flatMap(([key, value]) => {
      if (key === NETWORTH_CODE_NO_ASSETS) {
        return value[0];
      }
      return value;
    });
    await submitAsset({
      assets: transformedData,
      taskId: taskId,
    });
    eventAnalytics.track(FINANCIALPROFILE_ASSETS_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    await completeTask({ taskId });
  };

  useMemo(() => {
    setPrimaryAssetTypes(assetTypes?.slice(0, 4));
    setSecondaryAssetTypes(map(slice(assetTypes, 4), (obj) => ({ ...obj, isDisabled: false })));
  }, [assetTypes]);

  const [expandCollapsible, setExpandCollapsible] = useState<boolean[]>([false, false, false, false]);

  // on item checked set the form initial value
  const onToggle = async (item, index, noAssetIndex) => {
    setFormTouched(false);

    // set collapsible state to track the expandible div's
    await setExpandCollapsible((prevState) => {
      let newState;
      if (index === noAssetIndex) {
        newState = prevState.map(() => false);
        newState[index] = !prevState[index];
        reset({ assets: {} });
      } else {
        if (prevState[index]) {
          resetField(`assets.${item.code}`);
        }
        newState = [...prevState];
        newState[index] = !prevState[index];
      }
      return newState;
    });

    if (item.code === NETWORTH_CODE_NO_ASSETS) {
      setOtherAssetOpen(false);
      setDisableCollapsible(!disableCollapsible);
      setNoAssetData((prevState) =>
        isEmpty(prevState) ? { [`assets.${item.code}`]: [initialFormValues(item)] } : null
      );
    } else {
      expandCollapsible[index]
        ? setValue(`assets.${item.code}`, [])
        : setValue(`assets.${item.code}`, [initialFormValues(item)]);
    }
  };

  const onAddItem = (item) => {
    const allItems = watchAssets[item.code];
    allItems.push(initialFormValues(item));
    setValue(`assets.${item.code}`, allItems);
  };

  const onRemoveItem = async (code, index) => {
    const assetItems = await getValues(`assets.${code}`);
    const updatedAssetItems = assetItems.filter((_, i) => i !== index);
    await resetField(`assets.${code}`);
    await setValue(`assets.${code}`, updatedAssetItems);
  };

  // On add other assets to primary assets
  const onAddOtherAssets = (asset) => {
    if (!primaryAssetTypes.includes(asset)) {
      setOtherAssetOpen(!otherAssetOpen);

      //add secondary asset above 'I don't own asset'
      const primaryAssets = primaryAssetTypes.slice(0);
      const primaryAssetsInsertIndex = primaryAssets.length - 1;
      primaryAssets.splice(primaryAssetsInsertIndex, 0, asset);
      setPrimaryAssetTypes(primaryAssets);

      onToggle(asset, primaryAssetsInsertIndex, primaryAssetsInsertIndex + 1);

      setSecondaryAssetTypes((prevData) => {
        return prevData.map((data) => {
          if (data.id === asset.id) {
            return { ...data, isDisabled: true };
          }
          return data;
        });
      });
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        What are your <span className="text-primary">assets</span>?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {primaryAssetTypes?.map((asset, assetIndex) => (
          <div key={asset.id}>
            <CollapsibleHeader
              checkbox={true}
              disabled={asset.code === NETWORTH_CODE_NO_ASSETS ? false : disableCollapsible}
              title={asset.name}
              code={asset.code}
              valid={formTouched}
              onCollapseChange={() => onToggle(asset, assetIndex, primaryAssetTypes.length - 1)}
              open={expandCollapsible[assetIndex]}
            >
              {watchAssets[asset.code] &&
                watchAssets[asset.code].map((item, index) => (
                  <div key={index}>
                    <AssetItem
                      formData={watchAssets}
                      register={register}
                      index={index}
                      removeItem={onRemoveItem}
                      code={asset.code}
                    ></AssetItem>
                    {asset.code === NETWORTH_CODE_NO_ASSETS ||
                      (watchAssets[asset.code].length - 1 === index && (
                        <Button
                          className="mx-4 mb-3"
                          alignIcon="start"
                          onClick={() => onAddItem(asset)}
                          size="small"
                          variant="text"
                        >
                          + Another {asset.name}
                        </Button>
                      ))}
                  </div>
                ))}
            </CollapsibleHeader>
          </div>
        ))}
        <CollapsibleHeader
          disabled={disableCollapsible}
          chevron
          title={'More Assets'}
          valid={formTouched}
          open={otherAssetOpen}
          onCollapseChange={() => setOtherAssetOpen(!otherAssetOpen)}
        >
          <>
            {secondaryAssetTypes?.map((asset) => (
              <div key={asset.id}>
                <button
                  type="button"
                  onClick={() => onAddOtherAssets(asset)}
                  className={`hover:bg-grey-1 flex w-full cursor-pointer items-center justify-between space-x-4 bg-white p-4 ${
                    asset.isDisabled ? 'bg-grey-1 hover:bg-grey-1 cursor-not-allowed' : ''
                  }`}
                  disabled={asset.isDisabled}
                >
                  <div
                    key={`asset-${asset.id}`}
                    className={`flex cursor-pointer items-center space-x-4 ${
                      asset.isDisabled ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    <Image
                      src={`/assets/images/${kebabCase(asset.code)}.svg`}
                      className="grayscale"
                      alt={`${asset.code}_image`}
                      width={18}
                      height={18}
                    />
                    <Label className={` ${asset.isDisabled ? 'cursor-not-allowed' : ''}`}>
                      {capitalizeTitle(asset.name)}
                    </Label>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
                <Divider className="text-grey-2 my-0" />
              </div>
            ))}
          </>
        </CollapsibleHeader>
        {formTouched && <p className="text-error">Please select an option</p>}
        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          type="submit"
          className="mt-6"
          hasShadow
          isLoading={formSubmitting}
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
