/**
 * Please import icons alphabetically
 */

import { AccessTimeIcon } from './Icons/AccessTimeIcon';
import { AddIcon } from './Icons/AddIcon';
import { AddressCardIcon } from './Icons/AddressCardIcon';
import { AddTaskIcon } from './Icons/AddTaskIcon';
import { AppleIcon } from './Icons/AppleIcon';
import { ArrowCircleRightGradientIcon } from './Icons/ArrowCircleRightGradientIcon';
import { ArrowCircleRightIcon } from './Icons/ArrowCircleRightIcon';
import { ArrowCircleRightRoundedIcon } from './Icons/ArrowCircleRightRoundedIcon';
import { BankAccountIcon } from './Icons/BankAccountIcon';
import { BankStatementIcon } from './Icons/BankStatementIcon';
import { CalendarIcon } from './Icons/CalendarIcon';
import { CheckCircleIcon } from './Icons/CheckCircleIcon';
import { CheckCircleIconBlue } from './Icons/CheckCircleIconBlue';
import { ChecklistIcon } from './Icons/ChecklistIcon';
import { CheckRadioIcon } from './Icons/CheckRadioIcon';
import { CheckSquareIcon } from './Icons/CheckSquareIcon';
import { ChevronIcon } from './Icons/ChevronIcon';
import { ChevronRightIcon } from './Icons/ChevronRightIcon';
import { ClockIcon } from './Icons/ClockIcon';
import { ClockLightningIcon } from './Icons/ClockLightningIcon';
import { CloseCircleIcon } from './Icons/CloseCircleIcon';
import { CloseIcon } from './Icons/CloseIcon';
import { DashboardIcon } from './Icons/DashboardIcon';
import { DeleteIcon } from './Icons/DeleteIcon';
import { DollarIcon } from './Icons/DollarIcon';
import { DownloadIcon } from './Icons/DownloadIcon';
import { EmailIcon } from './Icons/EmailIcon';
import { EmailVerificationIcon } from './Icons/EmailVerificationIcon';
import { EmptyProgressIcon } from './Icons/EmptyProgressIcon';
import { ErrorCircleIcon } from './Icons/ErrorCircleIcon';
import { FacebookIcon } from './Icons/FacebookIcon';
import { FaceUnlockIcon } from './Icons/FaceUnlockIcon';
import { FileIcon } from './Icons/FileIcon';
import { FileUploadIcon } from './Icons/FileUploadIcon';
import { FindInPageIcon } from './Icons/FindInPageIcon';
import { FolderIcon } from './Icons/FolderIcon';
import { GoogleIcon } from './Icons/GoogleIcon';
import { GraphGrowthIcon } from './Icons/GraphGrowthIcon';
import { GraphIcon } from './Icons/GraphIcon';
import { HeaderCloseIcon } from './Icons/HeaderCloseIcon';
import { HeaderMenuIcon } from './Icons/HeaderMenuIcon';
import { HelpOutlineIcon } from './Icons/HelpOutlineIcon';
import { HomeIcon } from './Icons/HomeIcon';
import { HourglassIcon } from './Icons/HourglassIcon';
import { DebtConIcon } from './Icons/IconsWithVariant/DebtConIcon';
import { FlagIcon } from './Icons/IconsWithVariant/FlagIcon';
import { ImprovingMyHomeIcon } from './Icons/IconsWithVariant/ImprovingMyHomeIcon';
import { InfoIcon } from './Icons/InfoIcon';
import { LightBulbIcon } from './Icons/LightBulbIcon';
import { LoginNoViewIcon } from './Icons/LoginNoViewIcon';
import { MenuIcon } from './Icons/MenuIcon';
import { MobileFriendlyIcon } from './Icons/MobileFriendlyIcon';
import { NeedHelpIcon } from './Icons/NeedHelpIcon';
import { NoAccountChangesIcon } from './Icons/NoAccountChangesIcon';
import { PendingBankStatementIcon } from './Icons/PendingBankStatementIcon';
import { PersonIcon } from './Icons/PersonIcon';
import { PriceCheckIcon } from './Icons/PriceCheckIcon';
import { ProfileIcon } from './Icons/ProfileIcon';
import { PuzzleIcon } from './Icons/PuzzleIcon';
import { RadioSelectIcon } from './Icons/RadioSelectIcon';
import { ReadOnlyIcon } from './Icons/ReadOnlyIcon';
import { RefreshIcon } from './Icons/RefreshIcon';
import { SavingIcon } from './Icons/SavingIcon';
import { SignOutIcon } from './Icons/SignOutIcon';
import { StarIcon } from './Icons/StarIcon';
import { StaticProgressIcon } from './Icons/StaticProgressIcon';
import { StickyNoteIcon } from './Icons/StickyNoteIcon';
import { UncheckSquareIcon } from './Icons/UncheckSquareIcon';
import { UserCheckIcon } from './Icons/UserCheckIcon';
import { ValidIdIcon } from './Icons/ValidIdIcon';
import { VerificationErrorIcon } from './Icons/VerificationErrorIcon';
import { VerificationSuccessIcon } from './Icons/VerificationSuccessIcon';
import { VerifyIcon } from './Icons/VerifyIcon';
import { WarningIcon } from './Icons/WarningIcon';
import { WorkIcon } from './Icons/WorkIcon';

export * from './Icon';
export * from './V2';
export { AddIcon } from './Icons/AddIcon';
export { ClockLightningIcon } from './Icons/ClockLightningIcon';
export { FacebookIcon } from './Icons/FacebookIcon';
export { AddressCardIcon } from './Icons/AddressCardIcon';
export { AddTaskIcon } from './Icons/AddTaskIcon';
export { AppleIcon } from './Icons/AppleIcon';
export { ArrowCircleRightGradientIcon } from './Icons/ArrowCircleRightGradientIcon';
export { ArrowCircleRightIcon } from './Icons/ArrowCircleRightIcon';
export { ArrowCircleRightRoundedIcon } from './Icons/ArrowCircleRightRoundedIcon';
export { BankAccountIcon } from './Icons/BankAccountIcon';
export { BankStatementIcon } from './Icons/BankStatementIcon';
export { CalendarIcon } from './Icons/CalendarIcon';
export { CheckCircleIcon } from './Icons/CheckCircleIcon';
export { CheckCircleIconBlue } from './Icons/CheckCircleIconBlue';
export { CheckRadioIcon } from './Icons/CheckRadioIcon';
export { CheckSquareIcon } from './Icons/CheckSquareIcon';
export { ChevronIcon } from './Icons/ChevronIcon';
export { ChevronRightIcon } from './Icons/ChevronRightIcon';
export { ClockIcon } from './Icons/ClockIcon';
export { CloseCircleIcon } from './Icons/CloseCircleIcon';
export { CloseIcon } from './Icons/CloseIcon';
export { DeleteIcon } from './Icons/DeleteIcon';
export { DisabledSquareIcon } from './Icons/DisabledSquareIcon';
export { DollarIcon } from './Icons/DollarIcon';
export { DownloadIcon } from './Icons/DownloadIcon';
export { EmailIcon } from './Icons/EmailIcon';
export { EmailVerificationIcon } from './Icons/EmailVerificationIcon';
export { EmptyProgressIcon } from './Icons/EmptyProgressIcon';
export { ErrorCircleIcon } from './Icons/ErrorCircleIcon';
export { FaceUnlockIcon } from './Icons/FaceUnlockIcon';
export { FlagIcon } from './Icons/IconsWithVariant/FlagIcon';
export { GoogleIcon } from './Icons/GoogleIcon';
export { GraphIcon } from './Icons/GraphIcon';
export { GraphGrowthIcon } from './Icons/GraphGrowthIcon';
export { HelpOutlineIcon } from './Icons/HelpOutlineIcon';
export { HomeIcon } from './Icons/HomeIcon';
export { HourglassIcon } from './Icons/HourglassIcon';
export { ImprovingMyHomeIcon } from './Icons/IconsWithVariant/ImprovingMyHomeIcon';
export { InfoIcon } from './Icons/InfoIcon';
export { LightBulbIcon } from './Icons/LightBulbIcon';
export { LoginNoViewIcon } from './Icons/LoginNoViewIcon';
export { MenuIcon } from './Icons/MenuIcon';
export { NoAccountChangesIcon } from './Icons/NoAccountChangesIcon';
export { PendingBankStatementIcon } from './Icons/PendingBankStatementIcon';
export { PuzzleIcon } from './Icons/PuzzleIcon';
export { RadioSelectIcon } from './Icons/RadioSelectIcon';
export { ReadOnlyIcon } from './Icons/ReadOnlyIcon';
export { StarIcon } from './Icons/StarIcon';
export { StaticProgressIcon } from './Icons/StaticProgressIcon';
export { StickyNoteIcon } from './Icons/StickyNoteIcon';
export { UserCheckIcon } from './Icons/UserCheckIcon';
export { UncheckSquareIcon } from './Icons/UncheckSquareIcon';
export { ValidIdIcon } from './Icons/ValidIdIcon';
export { VerificationErrorIcon } from './Icons/VerificationErrorIcon';
export { VerificationSuccessIcon } from './Icons/VerificationSuccessIcon';
export { VerifyIcon } from './Icons/VerifyIcon';
export { WarningIcon } from './Icons/WarningIcon';
export { AccessTimeIcon } from './Icons/AccessTimeIcon';
export { RefreshIcon } from './Icons/RefreshIcon';
export { PersonIcon } from './Icons/PersonIcon';
export { HeaderMenuIcon } from './Icons/HeaderMenuIcon';
export { HeaderCloseIcon } from './Icons/HeaderCloseIcon';
export { DashboardIcon } from './Icons/DashboardIcon';
export { NeedHelpIcon } from './Icons/NeedHelpIcon';
export { SignOutIcon } from './Icons/SignOutIcon';
export { ProfileIcon } from './Icons/ProfileIcon';
export { WorkIcon } from './Icons/WorkIcon';
export { PriceCheckIcon } from './Icons/PriceCheckIcon';
export { SavingIcon } from './Icons/SavingIcon';
export { FindInPageIcon } from './Icons/FindInPageIcon';
export { ChecklistIcon } from './Icons/ChecklistIcon';
export { getIconComponent } from './Icons/IconsWithVariant';
export { MobileFriendlyIcon } from './Icons/MobileFriendlyIcon';
export { DebtConIcon } from './Icons/IconsWithVariant/DebtConIcon';
export { FileIcon } from './Icons/FileIcon';
export { FolderIcon } from './Icons/FolderIcon';

export const HmyIcons = [
  {
    name: 'AddIcon',
    icon: AddIcon,
  },
  {
    name: 'AddressCardIcon',
    icon: AddressCardIcon,
  },
  {
    name: 'AddTaskIcon',
    icon: AddTaskIcon,
  },
  {
    name: 'AppleIcon',
    icon: AppleIcon,
  },
  {
    name: 'ArrowCircleRightGradientIcon',
    icon: ArrowCircleRightGradientIcon,
  },
  {
    name: 'ArrowCircleRightIcon',
    icon: ArrowCircleRightIcon,
  },
  {
    name: 'ArrowCircleRightRoundedIcon',
    icon: ArrowCircleRightRoundedIcon,
  },
  {
    name: 'BankAccountIcon',
    icon: BankAccountIcon,
  },
  {
    name: 'UncheckSquareIcon',
    icon: UncheckSquareIcon,
  },
  {
    name: 'BankStatementIcon',
    icon: BankStatementIcon,
  },
  {
    name: 'CalendarIcon',
    icon: CalendarIcon,
  },
  {
    name: 'CheckCircleIcon',
    icon: CheckCircleIcon,
  },
  {
    name: 'CheckCircleIconBlue',
    icon: CheckCircleIconBlue,
  },
  {
    name: 'ChecklistIcon',
    icon: ChecklistIcon,
  },
  {
    name: 'CheckRadioIcon',
    icon: CheckRadioIcon,
  },
  {
    name: 'CheckSquareIcon',
    icon: CheckSquareIcon,
  },
  {
    name: 'ChevronIcon',
    icon: ChevronIcon,
  },
  {
    name: 'ChevronRightIcon',
    icon: ChevronRightIcon,
  },
  {
    name: 'ClockIcon',
    icon: ClockIcon,
  },
  {
    name: 'ClockLightningIcon',
    icon: ClockLightningIcon,
  },
  {
    name: 'CloseCircleIcon',
    icon: CloseCircleIcon,
  },
  {
    name: 'CloseIcon',
    icon: CloseIcon,
  },
  {
    name: 'DeleteIcon',
    icon: DeleteIcon,
  },
  {
    name: 'DownloadIcon',
    icon: DownloadIcon,
  },
  {
    name: 'DollarIcon',
    icon: DollarIcon,
  },
  {
    name: 'EmailIcon',
    icon: EmailIcon,
  },
  {
    name: 'EmailVerificationIcon',
    icon: EmailVerificationIcon,
  },
  {
    name: 'EmptyProgressIcon',
    icon: EmptyProgressIcon,
  },
  {
    name: 'ErrorCircleIcon',
    icon: ErrorCircleIcon,
  },
  {
    name: 'FacebookIcon',
    icon: FacebookIcon,
  },
  {
    name: 'FaceUnlockIcon',
    icon: FaceUnlockIcon,
  },
  {
    name: 'FindInPageIcon',
    icon: FindInPageIcon,
  },
  {
    name: 'FlagIcon',
    icon: FlagIcon,
  },
  {
    name: 'FileIcon',
    icon: FileIcon,
  },
  {
    name: 'FolderIcon',
    icon: FolderIcon,
  },
  {
    name: 'FolderUploadIcon',
    icon: FileUploadIcon,
  },
  {
    name: 'GoogleIcon',
    icon: GoogleIcon,
  },
  {
    name: 'GraphIcon',
    icon: GraphIcon,
  },
  {
    name: 'GraphGrowthIcon',
    icon: GraphGrowthIcon,
  },
  {
    name: 'HelpOutlineIcon',
    icon: HelpOutlineIcon,
  },
  {
    name: 'HomeIcon',
    icon: HomeIcon,
  },
  { name: 'HourglassIcon', icon: HourglassIcon },
  { name: 'ImprovingMyHomeIcon', icon: ImprovingMyHomeIcon },
  {
    name: 'InfoIcon',
    icon: InfoIcon,
  },
  {
    name: 'LightBulbIcon',
    icon: LightBulbIcon,
  },
  {
    name: 'LoginNoViewIcon',
    icon: LoginNoViewIcon,
  },
  {
    name: 'MenuIcon',
    icon: MenuIcon,
  },
  {
    name: 'MobileFriendlyIcon',
    icon: MobileFriendlyIcon,
  },
  {
    name: 'PendingBankStatementIcon',
    icon: PendingBankStatementIcon,
  },
  {
    name: 'PuzzleIcon',
    icon: PuzzleIcon,
  },
  {
    name: 'RadioSelectIcon',
    icon: RadioSelectIcon,
  },
  {
    name: 'ReadOnlyIcon',
    icon: ReadOnlyIcon,
  },
  {
    name: 'StarIcon',
    icon: StarIcon,
  },
  {
    name: 'StaticProgressIcon',
    icon: StaticProgressIcon,
  },
  {
    name: 'StickyNoteIcon',
    icon: StickyNoteIcon,
  },
  {
    name: 'UserCheckIcon',
    icon: UserCheckIcon,
  },
  {
    name: 'ValidIdIcon',
    icon: ValidIdIcon,
  },
  {
    name: 'VerificationErrorIcon',
    icon: VerificationErrorIcon,
  },
  {
    name: 'VerificationSuccessIcon',
    icon: VerificationSuccessIcon,
  },
  {
    name: 'VerifyIcon',
    icon: VerifyIcon,
  },
  {
    name: 'WarningIcon',
    icon: WarningIcon,
  },
  {
    name: 'AccessTimeIcon',
    icon: AccessTimeIcon,
  },
  {
    name: 'RefreshIcon',
    icon: RefreshIcon,
  },
  {
    name: 'PersonIcon',
    icon: PersonIcon,
  },
  {
    name: 'PriceCheckIcon',
    icon: PriceCheckIcon,
  },
  {
    name: 'HeaderMenuIcon',
    icon: HeaderMenuIcon,
  },
  {
    name: 'HeaderCloseIcon',
    icon: HeaderCloseIcon,
  },
  {
    name: 'DashboardIcon',
    icon: DashboardIcon,
  },
  {
    name: 'ProfileIcon',
    icon: ProfileIcon,
  },
  {
    name: 'NeedHelpIcon',
    icon: NeedHelpIcon,
  },
  {
    name: 'SignOutIcon',
    icon: SignOutIcon,
  },
  {
    name: 'SavingIcon',
    icon: SavingIcon,
  },
  {
    name: 'FindInPageIcon',
    icon: FindInPageIcon,
  },
  {
    name: 'PriceCheckIcon',
    icon: PriceCheckIcon,
  },
  {
    name: 'WorkIcon',
    icon: WorkIcon,
  },
  {
    name: 'NoAccountChangesIcon',
    icon: NoAccountChangesIcon,
  },
  {
    name: 'DebtConIcon',
    icon: DebtConIcon,
  },
];
