import React, { useMemo } from 'react';
import { ProgressStatus, UiQuoteStatus } from '@harmoney/api-interfaces';
import { CheckCircleIconBlue, EmptyProgressIcon, ProgressBar, StaticProgressIcon } from '@harmoney/ui-design-system';

export type UiQuoteStatusWithProgress = UiQuoteStatus & { status: ProgressStatus; style: string; icon: JSX.Element };

type Task = {
  status: string;
  taskDefinitionId: string;
};

type Props = {
  progress: UiQuoteStatusWithProgress[];
  taskTotal: number;
  display?: 'list' | 'bar';
};

export const initialProgress = (uiStatusMap: UiQuoteStatus[]): UiQuoteStatusWithProgress[] => {
  return uiStatusMap.map((uiStatus) => {
    return {
      ...uiStatus,
      status: uiStatus.displayOrder === 0 ? 'in-progress' : 'not-started',
      style: uiStatus.displayOrder === 0 ? 'font-medium' : 'text-grey-2',
      icon:
        uiStatus.displayOrder === 0 ? (
          <StaticProgressIcon size="tiny" className="rotate-[90deg]" />
        ) : (
          <EmptyProgressIcon size="tiny" />
        ),
    };
  });
};

export const trackProgress = (completedTasks: Task[], incompleteTasks: Task[], uiStatus: UiQuoteStatus) => {
  const completedTasksForUiStatus = completedTasks?.filter((task) =>
    uiStatus.taskDefinitionIds.includes(task.taskDefinitionId)
  );

  const incompleteTasksForUiStatus = incompleteTasks?.filter((task) =>
    uiStatus.taskDefinitionIds.includes(task.taskDefinitionId)
  );

  const status: ProgressStatus =
    completedTasksForUiStatus.length >= uiStatus.taskDefinitionIds.length && incompleteTasksForUiStatus.length === 0
      ? 'completed'
      : incompleteTasksForUiStatus?.length > 0
      ? 'in-progress'
      : 'not-started';
  const style = status === 'completed' ? 'text-grey-4' : status === 'in-progress' ? 'font-medium' : 'text-grey-2';
  const icon =
    status === 'completed' ? (
      <CheckCircleIconBlue size="tiny" />
    ) : status === 'in-progress' ? (
      <StaticProgressIcon size="tiny" className="rotate-[90deg]" />
    ) : (
      <EmptyProgressIcon size="tiny" />
    );
  return { ...uiStatus, status, style, icon };
};

export const ProgressRender = ({ progress, taskTotal, display = 'list' }: Props) => {
  const currentProgress = useMemo(() => {
    if (!progress) return 0;
    return progress.filter((uiStatus) => uiStatus.status === 'completed').length;
  }, [progress]);

  if (display === 'bar') {
    return (
      <div className="flex flex-col">
        <div className="mb-2 flex flex-row items-center justify-between">
          <span className="font-medium">Your Quote Progress</span>
          <span className="text-sm">{`${
            currentProgress >= taskTotal ? taskTotal : currentProgress
          }/${taskTotal} completed`}</span>
        </div>
        <ProgressBar progress={(currentProgress / taskTotal) * 100} totalSteps={taskTotal} />
      </div>
    );
  }

  return (
    <div className="border-b-grey-2 border-b p-4">
      <div className="mb-2 flex flex-row justify-between">
        <span className="font-medium">Your progress</span>
        <span className="text-sm">{`${currentProgress}/${taskTotal} completed`}</span>
      </div>
      <ol className="space-y-1">
        {progress?.map((uiStatus) => (
          <li key={uiStatus.name} className="flex flex-row items-stretch justify-start space-x-2">
            <span>{uiStatus.icon}</span>
            <span className={`first-letter:capitalize ${uiStatus.style} text-sm`}>{uiStatus.name}</span>
          </li>
        ))}
      </ol>
    </div>
  );
};
