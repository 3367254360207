export const PendingBankStatementIcon = () => {
  return (
    <svg width="280" height="141" viewBox="0 0 280 141" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.9292 77.0919H12.8024C8.74578 77.0919 5.46364 80.3763 5.46364 84.3907L5.42676 128.183C5.42676 132.198 8.74578 135.482 12.8024 135.482H71.8072C75.8638 135.482 79.1829 132.198 79.1829 128.183V91.6894C79.1829 87.6751 75.8638 84.3907 71.8072 84.3907H42.3048L34.9292 77.0919Z"
        fill="#73A4F5"
      />
      <path
        d="M30.3198 81.7016H8.19299C4.13641 81.7016 0.854261 84.9861 0.854261 89.0004L0.817383 132.793C0.817383 136.807 4.13641 140.092 8.19299 140.092H67.1979C71.2545 140.092 74.5735 136.807 74.5735 132.793V96.2992C74.5735 92.2849 71.2545 89.0004 67.1979 89.0004H37.6954L30.3198 81.7016Z"
        fill="#CFE0FC"
      />
      <path d="M31.2563 112.214H27.7441V120.409H31.2563V112.214Z" fill="#206EEE" />
      <path d="M38.8657 112.214H35.3535V120.409H38.8657V112.214Z" fill="#206EEE" />
      <path d="M48.818 122.75H25.4033V126.262H48.818V122.75Z" fill="#206EEE" />
      <path d="M46.477 112.214H42.9648V120.409H46.477V112.214Z" fill="#206EEE" />
      <path d="M37.1106 101.677L25.4033 107.531V109.872H48.818V107.531L37.1106 101.677Z" fill="#206EEE" />
      <path
        d="M234.929 77.0919H212.802C208.746 77.0919 205.464 80.3763 205.464 84.3907L205.427 128.183C205.427 132.198 208.746 135.482 212.802 135.482H271.807C275.864 135.482 279.183 132.198 279.183 128.183V91.6894C279.183 87.6751 275.864 84.3907 271.807 84.3907H242.305L234.929 77.0919Z"
        fill="#FF8D8C"
      />
      <path
        d="M230.32 81.7016H208.193C204.136 81.7016 200.854 84.9861 200.854 89.0004L200.817 132.793C200.817 136.807 204.136 140.092 208.193 140.092H267.198C271.254 140.092 274.573 136.807 274.573 132.793V96.2992C274.573 92.2849 271.254 89.0004 267.198 89.0004H237.695L230.32 81.7016Z"
        fill="#FFD6D2"
      />
      <circle cx="237.695" cy="113.97" r="13.8293" fill="#FF4B4A" />
      <path
        d="M242.144 106.754C241.11 106.754 240.593 107.163 240.593 107.981V112.094C240.593 112.142 240.569 112.202 240.521 112.226C239.715 112.767 238.741 113.092 237.695 113.092C236.649 113.092 235.675 112.767 234.869 112.226C234.821 112.202 234.797 112.142 234.797 112.094V107.981C234.797 107.163 234.292 106.754 233.246 106.754C232.211 106.754 231.694 107.295 231.694 107.981V119.561C231.694 120.379 232.199 120.788 233.246 120.788C234.28 120.788 234.797 120.379 234.797 119.561V115.148C235.687 115.545 236.661 115.773 237.695 115.773C238.729 115.773 239.715 115.545 240.593 115.148V119.561C240.593 120.379 241.098 120.788 242.144 120.788C243.179 120.788 243.696 120.379 243.696 119.561V107.981C243.684 107.295 243.179 106.754 242.144 106.754Z"
        fill="white"
      />
      <path
        d="M38.8174 64.0919C42.7445 48.3183 71.4424 17.2525 154.817 19.1774"
        stroke="#DBDBDB"
        strokeWidth="4"
        strokeDasharray="12 12"
      />
      <path
        d="M204.949 30.1669C204.844 30.9948 204.282 31.6937 203.494 31.9591L176.438 41.1631C175.807 41.3773 175.11 41.2888 174.551 40.9217C173.992 40.5545 173.632 39.9532 173.576 39.286L172.814 30.525L166.202 28.7265C165.339 28.4951 164.707 27.7526 164.61 26.8613C164.513 25.9701 164.978 25.1149 165.773 24.7006L170.806 22.0765C171.047 21.951 171.323 21.922 171.583 21.9936L187.856 26.3438C188.417 26.4953 188.995 26.1788 189.168 25.6292C189.341 25.0796 189.06 24.483 188.519 24.2826L171.255 17.7078L171.145 11.0039C171.131 10.1994 171.556 9.45722 172.257 9.06877C172.958 8.68033 173.815 8.70836 174.49 9.13731L203.966 28.0758C204.669 28.5286 205.047 29.3422 204.949 30.1669Z"
        fill="#73A4F5"
      />
    </svg>
  );
};
