import { generateIcon } from '../generateIcon';

export const CloseIcon = generateIcon({
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M0.471212 13.2702C-0.153738 13.8952 -0.153738 14.9076 0.471212 15.5325C0.781188 15.845 1.19116 16 1.60112 16C2.01109 16 2.42006 15.8438 2.73204 15.5313L8.00062 10.2654L13.2687 15.53C13.5812 15.845 13.9907 16 14.4001 16C14.8096 16 15.2186 15.845 15.5313 15.53C16.1562 14.9051 16.1562 13.8927 15.5313 13.2677L10.2617 7.99813L15.5313 2.73104C16.1562 2.10609 16.1562 1.09366 15.5313 0.468713C14.9063 -0.156238 13.8939 -0.156238 13.269 0.468713L8.00062 5.7408L2.73104 0.471213C2.10609 -0.153738 1.09366 -0.153738 0.468713 0.471213C-0.156239 1.09616 -0.156239 2.10859 0.468713 2.73354L5.7383 8.00312L0.471212 13.2702Z"
      fill="#B2B2B2"
    />
  ),
});
