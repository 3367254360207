import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DocumentCategoryEnum } from '@prisma/client';

import { Button } from '../Button';
import { Card } from '../Card';
import { Checkbox } from '../Checkbox';
import { HelpText } from '../HelpText';
import { FileUploadIcon } from '../Icon/Icons/FileUploadIcon';

import { FilenameBadge } from './FilenameBadge/FilenameBadge';

export interface FileUpload {
  label: string;
  helpText: string;
  keyName: string;
  multipleFiles: boolean;
  hasError?: boolean;
  extAllowed?: string;
  showCheckBox?: boolean;
  maxSize?: number;
  category?: DocumentCategoryEnum;
  onFilesSelected?: (files: File[]) => void;
}

export const FileUpload = ({
  label,
  helpText,
  keyName,
  multipleFiles = true,
  hasError = false,
  extAllowed,
  showCheckBox = false,
  onFilesSelected,
  maxSize,
}: FileUpload) => {
  const { register } = useFormContext();
  const exts = extAllowed || '.pdf, .doc, .docx, .xls, .xlsx, .csv';
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [customError, setCustomError] = useState<null | string>();
  const [isChecked, setChecked] = useState(false);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleCheckboxClick = () => {
    setChecked(!isChecked);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    const filesArray: File[] = Array.from(files);
    const handleOversizedFiles = () => {
      if (maxSize) {
        const oversizedFiles = filesArray.filter((file) => file.size > 1024 * 1024 * maxSize);

        if (oversizedFiles.length > 0) {
          const oversizedFileNames = oversizedFiles
            .map((file) => file.name)
            .join(', ')
            .toLowerCase();
          setCustomError(`File ${oversizedFileNames} size exceeds the maximum limit of ${maxSize} megabytes`);
          return true;
        }
      }

      return false;
    };

    const handleInvalidFileNames = () => {
      if (filesArray.length === 0) {
        return false;
      }

      const isFileNameValid = filesArray.some((file) => isValidString(file.name));

      if (!isFileNameValid) {
        setCustomError('Please use only English characters, digits, hyphens, or underscores in the file name.');
      } else {
        setCustomError(null);
      }

      return !isFileNameValid;
    };

    if (handleOversizedFiles() || handleInvalidFileNames()) {
      return;
    }

    const updatedFiles = [...selectedFiles, ...filesArray];
    setSelectedFiles(updatedFiles);
    onFilesSelected && onFilesSelected(updatedFiles);
  };

  function isValidString(input: string): boolean {
    const pattern = /^[a-zA-Z0-9-_. ]+$/;
    return pattern.test(input);
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setCustomError(null);
    onFilesSelected && onFilesSelected(updatedFiles);
  };

  return (
    <>
      <Card
        className={`${hasError ? 'border-error' : 'border-grey-2'}  mb-0 w-full self-center border py-4 shadow-none`}
      >
        <label className="font-medium">{label}</label>
        <HelpText className="mb-4">{helpText}</HelpText>
        <div className="mb-4">
          <input
            type="file"
            ref={fileInputRef}
            multiple={multipleFiles}
            onChange={handleFileChange}
            className="hidden"
            accept={exts}
          />
          <Button
            alignIcon="start"
            onClick={handleButtonClick}
            className="mb-2"
            icon={<FileUploadIcon size="small" />}
            variant="outline-secondary"
            disabled={isChecked}
          >
            Upload File
          </Button>
        </div>

        {selectedFiles.length > 0 && (
          <FilenameBadge items={selectedFiles} onRemoveFile={(index) => handleRemoveFile(index)}></FilenameBadge>
        )}
        {customError && <p className="text-error leading-sm ml-2 mt-1 pb-2 text-sm">{customError}</p>}
        {showCheckBox && selectedFiles.length <= 0 && (
          <Checkbox
            {...register(keyName)}
            name={keyName}
            label="Don’t have this document"
            onClick={handleCheckboxClick}
          />
        )}
      </Card>
    </>
  );
};
