export type FractionDigitsForWholeNumber = 0 | 2;

export const DEFAULT_FRACTION_DIGITS = 2;
export const DEFAULT_WHOLE_NUMBER_FRACTION_DIGITS = 0;

export const determineFractionDigits = (
  value: number,
  fractionDigitsForWholeNumber: FractionDigitsForWholeNumber
): number => {
  return Math.floor(value) === value ? fractionDigitsForWholeNumber : DEFAULT_FRACTION_DIGITS;
};

export const formatNumber = (
  value: number,
  fractionDigitsForWholeNumber: FractionDigitsForWholeNumber = DEFAULT_WHOLE_NUMBER_FRACTION_DIGITS
): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: determineFractionDigits(value, fractionDigitsForWholeNumber),
    maximumFractionDigits: determineFractionDigits(value, fractionDigitsForWholeNumber),
  }).format(value);
};
