import { generateIcon } from '../generateIcon';

export const ChevronIcon = generateIcon({
  viewBox: '0 0 12 8',
  variant: 'none',
  path: (
    <path
      d="M2.12021 1.29006L6.00022 5.17006L9.88021 1.29006C10.2702 0.900059 10.9002 0.900059 11.2902 1.29006C11.6802 1.68006 11.6802 2.31006 11.2902 2.70006L6.70021 7.29006C6.31021 7.68006 5.68021 7.68006 5.29022 7.29006L0.700215 2.70006C0.310215 2.31006 0.310215 1.68006 0.700215 1.29006C1.09021 0.910059 1.73021 0.900059 2.12021 1.29006Z"
      fill="#8E8E8E"
    />
  ),
});
