import { AssetDetails, DebtDetails, ExpenseDetails, IncomeDetails } from '@harmoney/components';
import { Accordion, CollapsibleCard, TextLink } from '@harmoney/ui-design-system';
import {
  AssetAndLiability,
  EmploymentCodeEnum,
  FinancialProfile,
  IncomeAndExpense,
  LoanApplication,
  NetworthSource,
  NetworthSourceKind,
  Prisma,
} from '@prisma/client';

import { SelfEmployedDetails } from './FinancialProfile/SelfEmployedDetails';

interface FinancialProfileDetailsProps {
  loanProduct?: string;
  instanceId?: string;
  loanApplicationData?: LoanApplication;
  rootData: Prisma.JsonValue;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
}

export const filterByNetworthSourceKind = <T extends AssetAndLiability | IncomeAndExpense>(
  data: (T & { networthSource: NetworthSource; isAccommodationExpense?: boolean })[] | undefined,
  kind: NetworthSourceKind
): (T & { networthSource: NetworthSource; isAccommodationExpense?: boolean })[] | undefined => {
  if (!data) return [];
  return data?.filter((item) => item.networthSource.kind === kind);
};

const filterByEmploymentCode = <T extends IncomeAndExpense>(
  data: (T & { networthSource: NetworthSource; isAccommodationExpense?: boolean })[] | undefined,
  employmentCode: EmploymentCodeEnum
): (T & { networthSource: NetworthSource; isAccommodationExpense?: boolean })[] | undefined => {
  if (!data) return [];
  return data?.filter((item) => item.networthSource.code?.toLowerCase() === employmentCode?.toLowerCase());
};

export const FinancialProfileDetails = ({
  instanceId,
  financialProfileData,
  loanApplicationData,
  loanProduct,
  rootData,
}: FinancialProfileDetailsProps) => {
  const operateURL = process.env.NEXT_PUBLIC_CAMUNDA_OPERATE_ENDPOINT;

  const incomes: (IncomeAndExpense & {
    networthSource: NetworthSource;
  })[] = filterByNetworthSourceKind(financialProfileData?.income_and_expense, NetworthSourceKind.INCOME);
  const selfEmployedIncomes: (IncomeAndExpense & {
    networthSource: NetworthSource;
  })[] = filterByEmploymentCode(financialProfileData?.income_and_expense, EmploymentCodeEnum.SELF_EMPLOYED);
  const expenses: (IncomeAndExpense & {
    networthSource: NetworthSource;
    isAccommodationExpense?: boolean;
  })[] = filterByNetworthSourceKind(financialProfileData?.income_and_expense, NetworthSourceKind.EXPENSE);
  const assets: (AssetAndLiability & {
    networthSource: NetworthSource;
  })[] = filterByNetworthSourceKind(financialProfileData?.asset_and_liability, NetworthSourceKind.ASSET);
  const liabilities: (AssetAndLiability & {
    networthSource: NetworthSource;
  })[] = filterByNetworthSourceKind(financialProfileData?.asset_and_liability, NetworthSourceKind.LIABILITY);

  const items = [
    {
      title: 'Income',
      content: <IncomeDetails incomeData={incomes} applicationStatus={loanApplicationData?.status} />,
    },
    {
      title: 'Expenses',
      content: <ExpenseDetails expenseData={expenses} applicationStatus={loanApplicationData?.status} />,
    },
    {
      title: 'Assets',
      content: <AssetDetails assetData={assets} />,
    },
    {
      title: 'Debts',
      content: <DebtDetails debtData={liabilities} loanProduct={loanProduct} rootData={rootData} />,
    },
    {
      title: 'Self employed',
      content: <SelfEmployedDetails incomeData={selfEmployedIncomes} loanApplicationData={loanApplicationData} />,
    },
  ];

  return (
    <CollapsibleCard title="Financial Profile" className="mb-6" defaultOpen={false}>
      <div className="grid grid-cols-2">
        <div className="p-4">
          <span className="grid grid-cols-2">
            <span>Link to operate:</span>
            {instanceId ? (
              <TextLink target="_blank" rel="noopener noreferrer" href={`${operateURL}/processes/${instanceId}`}>
                Financial profile
              </TextLink>
            ) : (
              'N/A'
            )}
          </span>
        </div>
      </div>
      <div className="p-4">
        <Accordion title="Details" items={items} />
      </div>
    </CollapsibleCard>
  );
};
