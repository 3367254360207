import { generateIcon } from '../generateIcon';

export const WarningIcon = generateIcon({
  viewBox: '0 0 20 18',
  path: (
    <path
      d="M2.47012 17.9999H17.5301C19.0701 17.9999 20.0301 16.3299 19.2601 14.9999L11.7301 1.98993C10.9601 0.659932 9.04012 0.659932 8.27012 1.98993L0.740121 14.9999C-0.0298788 16.3299 0.930121 17.9999 2.47012 17.9999V17.9999ZM10.0001 10.9999C9.45012 10.9999 9.00012 10.5499 9.00012 9.99993V7.99993C9.00012 7.44993 9.45012 6.99993 10.0001 6.99993C10.5501 6.99993 11.0001 7.44993 11.0001 7.99993V9.99993C11.0001 10.5499 10.5501 10.9999 10.0001 10.9999ZM11.0001 14.9999H9.00012V12.9999H11.0001V14.9999Z"
      fill="#FFC83B"
    />
  ),
});
