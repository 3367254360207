import { generateIcon } from '../generateIcon';

export const PriceCheckIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_6114_51860)">
        <path
          d="M12 13V9C12 8.45 11.55 8 11 8H7V6H12V4H9.5V3H7.5V4H6C5.45 4 5 4.45 5 5V9C5 9.55 5.45 10 6 10H10V12H5V14H7.5V15H9.5V14H11C11.55 14 12 13.55 12 13Z"
          fill="#73A4F5"
        />
        <path
          d="M19.5895 12.5195L13.9295 18.1695L11.0995 15.3395L9.68945 16.7595L13.9295 20.9995L20.9995 13.9295L19.5895 12.5195Z"
          fill="#73A4F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6114_51860">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
