import { SmartUIParameters } from '@harmoney/api-interfaces';
import { BranchEnum as Branch } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const identityVerificationApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSmartUIParameter: builder.query<SmartUIParameters, { userId: string; branch: Branch }>({
      query: ({ userId, branch }) => ({
        url: `/identity-verifications/machine-session?userId=${userId}&branch=${branch}`,
        method: 'GET',
      }),
    }),
    updateVerifiedUserProfile: builder.query<void, { branch: Branch; entityId: string }>({
      query: ({ branch, entityId }) => ({
        url: `/identity-verifications/handle-callback-delay`,
        method: 'GET',
        params: {
          branch,
          entityId,
        },
      }),
    }),
  }),
});

export const { useGetSmartUIParameterQuery, useLazyUpdateVerifiedUserProfileQuery } = identityVerificationApi;
