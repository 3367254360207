import { generateIcon } from '../generateIcon';

export const CloseCircleIcon = generateIcon({
  viewBox: '0 0 48 48',
  path: (
    <>
      <circle cx="24" cy="24" r="23" stroke="#FF4B4A" strokeWidth="2" fill="none" />
      <path d="M15.5996 32.4001L32.3996 15.6001" stroke="#FF4B4A" strokeWidth="2.8" strokeLinecap="round" />
      <path d="M15.5996 15.6001L32.3996 32.4001" stroke="#FF4B4A" strokeWidth="2.8" strokeLinecap="round" />
    </>
  ),
});
