import { generateIcon } from '../generateIcon';

export const DownloadIcon = generateIcon({
  viewBox: '0 0 24 25',
  path: (
    <>
      <g clipPath="url(#clip0_308_6904)">
        <path
          d="M19 9.92627H15V3.92627H9V9.92627H5L12 16.9263L19 9.92627ZM5 18.9263V20.9263H19V18.9263H5Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_6904">
          <rect width="24" height="24" fill="white" transform="translate(0 0.92627)" />
        </clipPath>
      </defs>
    </>
  ),
});
