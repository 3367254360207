import { generateIcon } from '../generateIcon';

export const DollarIcon = generateIcon({
  viewBox: '0 0 26 30',
  path: (
    <path
      d="M12.79 12.464V9.296c-1.271.12-1.967.72-1.967 1.536 0 .888.6 1.248 1.968 1.632Zm1.465 7.68c1.104-.144 2.016-.672 2.016-1.704 0-.984-.696-1.368-2.016-1.752v3.456Zm-1.464 5.328v-2.16c-3.96-.24-6.288-2.208-6.36-5.616h3.984c.168 1.464.936 2.232 2.376 2.448v-3.888c-.048 0-.072 0-.096-.024-3.24-.84-5.808-1.632-5.808-5.16 0-3.096 2.544-4.704 5.904-4.848V4.76h1.464v1.488c3.36.24 5.496 1.92 5.664 5.112H16.15c-.168-1.176-.816-1.8-1.896-2.016v3.504c.144.024.264.072.432.096 3.144.792 5.592 1.896 5.592 5.184 0 3.24-2.592 5.04-6.024 5.208v2.136H12.79Z"
      fill="#fff"
    />
  ),
});
