import { generateIcon } from '../generateIcon';

export const EmailVerificationIcon = generateIcon({
  viewBox: '0 0 180 120',
  path: (
    <>
      <g clipPath="url(#clip0_7211_24854)">
        <rect width="180" height="120" fill="white" />
        <circle cx="94" cy="59.7261" r="57" fill="#CFE0FC" />
        <path d="M50.5 67.7261L16 86.7261" stroke="#73A4F5" strokeWidth="5" strokeLinecap="round" />
        <path d="M59 90.5161L42 100.516" stroke="#73A4F5" strokeWidth="5" strokeLinecap="round" />
        <path
          d="M63.5 31.5L61.5 35.5L86 62L79 78.5L80 82.5L83 83L96.5 74.5L115 92L118.5 91L160.5 27.5L160 22.5L156 22L63.5 31.5Z"
          fill="white"
        />
        <path
          d="M111.659 24.3999C86.5618 26.7204 65.2555 28.9007 64.3568 29.2376C60.7854 30.534 59.6291 34.8542 62.0723 37.9529C62.7884 38.8376 68.0632 44.5903 73.7827 50.7315L84.2434 61.8859L80.7794 70.1892C76.454 80.5239 76.4633 82.1434 80.8911 84.1562C83.3624 85.2797 85.3845 84.5215 91.5959 80.0143C94.5307 77.9316 97.0209 76.1437 97.1192 76.0641C97.269 76.008 100.718 79.6262 104.833 84.1058C109.129 88.7304 113.093 92.5828 114.02 93.0041C115.874 93.8467 118.195 93.6595 119.721 92.4894C120.756 91.7172 160.984 30.4214 162.154 27.8471C162.622 26.8174 162.575 25.5536 162.065 24.0792C160.385 19.7121 161.803 19.7355 111.659 24.3999ZM122.691 41.4052C103.266 51.3366 87.0425 59.5551 86.7195 59.7189C86.0221 60.0231 63.6544 36.2492 63.2941 34.7186C62.4517 31.7885 63.2053 31.6341 111.458 27.1662C136.958 24.7802 157.9 22.9931 157.956 23.1429C158.012 23.2926 142.144 31.5486 122.691 41.4052ZM139.405 58.0736C128.723 74.4647 119.473 88.5249 118.968 89.227C118.462 89.9291 117.348 90.603 116.468 90.7622C115.059 90.9914 114.194 90.1629 101.673 76.7056C94.2971 68.8792 88.2594 62.3448 88.2313 62.2699C88.1517 62.1716 104.244 53.8314 123.945 43.8391C152.811 29.1292 159.701 25.8624 159.36 26.8874C159.158 27.6036 150.162 41.6544 139.405 58.0736ZM94.8539 73.6675C95.1581 74.365 85.4923 81.2778 83.5358 81.7552C81.6073 82.3074 81.2188 82.0687 80.5449 80.2713C80.0394 78.9232 80.2876 78.1041 83.26 71.5654L86.5134 64.4089L90.6228 68.7621C92.8132 71.1867 94.7416 73.368 94.8539 73.6675Z"
          fill="#434343"
        />
      </g>
      <defs>
        <clipPath id="clip0_7211_24854">
          <rect width="180" height="120" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
