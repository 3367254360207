import { useMemo } from 'react';
import { debtConsolidationBenefitMapper, opportunityMapper } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { CurrencyFormatter, DATE_TIME_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { isDebtCon } from '@harmoney/utilities';
import dayjs from 'dayjs';

import { Field } from './shared';

interface RequirementAndObjectivesDetailsProps {
  loanApplicationData: LoanApplicationQueryResponse;
}

export const RequirementAndObjectivesDetails = ({ loanApplicationData }: RequirementAndObjectivesDetailsProps) => {
  const loanPurposes = useMemo(() => {
    return loanApplicationData?.loanApplicationPurposes?.map(({ loanPurpose, loanApplicationPurposeAnswers }) => {
      const additionalEnquires = loanApplicationPurposeAnswers?.map(
        ({ id, loanPurposeQuestion, loanPurposeQuestionOption }) => {
          return {
            id,
            question: loanPurposeQuestion?.name,
            answer: loanPurposeQuestionOption?.name,
          };
        }
      );
      return {
        id: loanPurpose.id,
        displayName: loanPurpose.displayName,
        additionalEnquires,
      };
    });
  }, [loanApplicationData?.loanApplicationPurposes]);

  return (
    <CollapsibleCard title="Requirements & Objectives" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field>
            <span>Product:</span>
            <span>{loanApplicationData?.loanProduct?.name ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Opportunity:</span>
            <span>{opportunityMapper[loanApplicationData.originationType]}</span>
          </Field>
          {isDebtCon(loanApplicationData?.loanProduct?.name) && (
            <Field>
              <span>Debt Consolidation objectives:</span>
              <span>
                <ul className="list-disc space-y-1">
                  {loanApplicationData?.debtConsolidationBenefits?.map((benefit) => (
                    <li key={benefit}>{valueOrNA(debtConsolidationBenefitMapper[benefit])}</li>
                  ))}
                </ul>
              </span>
            </Field>
          )}
          <Field>
            <span>Requested amount:</span>
            <span>
              {loanApplicationData?.requestedAmount
                ? CurrencyFormatter.format(loanApplicationData?.requestedAmount)
                : 'N/A'}
            </span>
          </Field>
          <Field>
            <span>Funded amount:</span>
            <span>
              {loanApplicationData?.fundedAmount ? CurrencyFormatter.format(loanApplicationData?.fundedAmount) : 'N/A'}
            </span>
          </Field>
          <Field>
            <span>Loan purpose:</span>
            <span>
              {loanApplicationData?.loanApplicationPurposes
                .map((loanApplicationPurpose) => loanApplicationPurpose?.loanPurpose?.displayName)
                .join(', ')}
            </span>
          </Field>
          <Field>
            <span>Branch:</span>
            <span>{loanApplicationData?.loanProduct?.branch ?? 'N/A'}</span>
          </Field>
        </div>
        <div className="p-4">
          <Field>
            <span>Created date:</span>
            <span>{dayjs(loanApplicationData?.createdAt).format(DATE_TIME_FORMAT)}</span>
          </Field>
          <Field>
            <span>Application expiry date:</span>
            <span>
              {loanApplicationData?.applicationExpiryAt
                ? dayjs(loanApplicationData?.applicationExpiryAt).format(DATE_TIME_FORMAT)
                : 'N/A'}
            </span>
          </Field>
          <Field>
            <span>Quote expiry date:</span>
            <span>
              {loanApplicationData?.quoteExpiryAt
                ? dayjs(loanApplicationData?.quoteExpiryAt).format(DATE_TIME_FORMAT)
                : 'N/A'}
            </span>
          </Field>
          <Field>
            <span>Additional Enquiry:</span>
            <span>
              {loanPurposes?.map(({ additionalEnquires }) =>
                additionalEnquires?.map(({ id, question, answer }) => (
                  <span key={id} className="inline-block">
                    {question} - {answer}
                  </span>
                ))
              ) ?? 'N/A'}
            </span>
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};
