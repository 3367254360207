import { useEffect, useState } from 'react';
import { FULL_HISTORY_START_DATE } from '@harmoney/api-interfaces';
import { useGetAccountTransactionsForTimePeriodQuery } from '@harmoney/redux';
import { Button, CollapsibleCard, Spinner } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { LoanEventEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

interface AdminTransactionHistoryProps {
  loanNumber: string;
  loanApplicationId: string;
}

const TableRow = ({ tableRow, loanNumber }) => {
  let paymentType = '';
  switch (tableRow?.eventType) {
    case LoanEventEnum.ACCOUNT_CLOSED:
      paymentType = `Closing balance - ${loanNumber}`;
      break;
    case LoanEventEnum.ACCOUNT_OPEN:
      paymentType = `Opening balance - ${loanNumber}`;
      break;
    case LoanEventEnum.DISHONOR:
      paymentType = `Payment - ${tableRow?.originalPaymentKey} - REJECTED`;
      break;
    case LoanEventEnum.REFUND:
      paymentType = `Refund - ${tableRow?.paymentKey}`;
      break;
    case LoanEventEnum.REPAYMENT:
      paymentType = `Payment - ${tableRow?.paymentKey}`;
      break;
    case LoanEventEnum.REVERSAL:
      paymentType = `Payment - ${tableRow?.originalPaymentKey} - REVERSED`;
      break;
    default:
      paymentType = `${capitalize(tableRow?.eventType)} - ${tableRow?.paymentKey}`;
  }
  return (
    <tr className="border-b-2 border-b-grey-1">
      <td className="p-4">{dayjs(tableRow?.eventTimestamp).format(DATE_FORMAT)}</td>
      <td className="p-4">{paymentType}</td>
      <td className="p-4">{tableRow?.amount !== null && formatCurrency(tableRow?.amount)}</td>
      <td className="p-4">{tableRow?.principalPaid !== null && formatCurrency(tableRow?.principalPaid)}</td>
      <td className="p-4">{tableRow?.interestPaid !== null && formatCurrency(tableRow?.interestPaid)}</td>
      <td className="p-4">{tableRow?.penaltiesPaid !== null && formatCurrency(tableRow?.penaltiesPaid)}</td>
      <td className="p-4">{tableRow?.principal !== null && formatCurrency(tableRow?.principal)}</td>
    </tr>
  );
};

export const AdminTransactionHistory = ({ loanNumber, loanApplicationId }: AdminTransactionHistoryProps) => {
  const today = dayjs().format('YYYY-MM-DD');

  const { data: allTransactions, isLoading } = useGetAccountTransactionsForTimePeriodQuery(
    {
      loanApplicationId,
      startDate: FULL_HISTORY_START_DATE,
      endDate: today,
      order: 'desc',
    },
    {
      skip: !loanApplicationId,
    }
  );

  const itemsPerPage = 10;
  const totalItems = allTransactions?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = Math.min(startIdx + itemsPerPage, totalItems);
    setVisibleItems(allTransactions?.slice(startIdx, endIdx));
  }, [allTransactions, currentPage, totalItems]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CollapsibleCard title="Transaction History" className="mb-6" defaultOpen>
      <div className="overflow-auto max-h-[700px]">
        {totalItems === 0 ? (
          <div className="p-4 text-grey-4">No transactions found</div>
        ) : (
          <>
            <table className="w-full border-collapse bg-white text-sm table-auto">
              <thead className="text-left">
                <tr className="border-b-2 border-b-grey-1">
                  <th className="p-4">Date</th>
                  <th className="p-4">Payment Type</th>
                  <th className="p-4">Amount</th>
                  <th className="p-4">Principal Paid</th>
                  <th className="p-4">Interest Paid</th>
                  <th className="p-4">Other</th>
                  <th className="p-4">Balance after transaction</th>
                </tr>
              </thead>
              <tbody>
                {visibleItems?.map((row, index) => <TableRow key={index} tableRow={row} loanNumber={loanNumber} />)}
              </tbody>
            </table>
            <div className="flex p-4 items-center justify-between text-sm">
              <span className="text-grey-4">
                Showing {(currentPage - 1) * itemsPerPage + visibleItems?.length} out of {totalItems} transactions
              </span>
              <div className="flex items-center">
                <Button
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                  variant="text"
                  className="mr-6"
                  icon={<Icon icon="material-symbols:first-page" width={24} />}
                  alignIcon="start"
                >
                  Newest
                </Button>
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant="text"
                  className="mr-6"
                  icon={<Icon icon="material-symbols:chevron-left" width={24} />}
                  alignIcon="start"
                >
                  Newer
                </Button>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  variant="text"
                  className="mr-6"
                  icon={<Icon icon="material-symbols:chevron-right" width={24} />}
                  alignIcon="start"
                >
                  Older
                </Button>
                <Button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  variant="text"
                  icon={<Icon icon="material-symbols:last-page" width={24} />}
                  alignIcon="start"
                >
                  Oldest
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </CollapsibleCard>
  );
};
