import { useFriendlyURL } from '@harmoney/hooks';
import { useUpdateUserMutation } from '@harmoney/redux';
import { eventAnalytics, GETTING_TO_KNOW_YOU_PROVIDED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, ControlledInput, Form, Label, useForm } from '@harmoney/ui-design-system';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

type Props = {
  handleChange?: () => void;
} & CommonProps;

const formSchema = z.object({
  preferredName: z
    .string({
      required_error: 'Please enter your name',
    })
    .min(1, {
      message: 'Please enter your name',
    }),
});

type FormValues = z.infer<typeof formSchema>;

export function Username({ taskId, completeTask, handleChange, taskFriendlyURL }: Props) {
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
  });
  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const [updateUser] = useUpdateUserMutation();

  const handleSubmit = async (data: FormValues) => {
    await updateUser(data);

    eventAnalytics.track(GETTING_TO_KNOW_YOU_PROVIDED, {
      taskid_str: taskId,
    });

    handleChange && handleChange();
    completeTask && completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Hello, let&rsquo;s <span className="text-primary">get started</span>!
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <Label className="mb-2">What is your preferred name?</Label>
          <ControlledInput {...register('preferredName')} displayValidation={false} />
        </Card>

        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
