import { loanStatusMapper } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse, UserInstanceQueryResponse } from '@harmoney/redux';
import { isDebtCon } from '@harmoney/utilities';
import { LoanApplicationStatusEnum, LoanArrearsStatusEnum, VaultLoanAccountStateEnum } from '@prisma/client';

export const isQuote = (loanApplicationStatus: LoanApplicationStatusEnum): boolean => {
  if (!loanApplicationStatus) return false;
  return !!loanApplicationStatus.startsWith('quote');
};

export const isApplication = (loanApplicationStatus: LoanApplicationStatusEnum): boolean => {
  if (!loanApplicationStatus) return false;
  return !!loanApplicationStatus.startsWith('application');
};

export const loanStatusFormatter = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  const mappedStatus = loanStatusMapper[loanApplicationStatus];
  return mappedStatus.replace(' -', '');
};

export const hasReachedQuote = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  return !!(loanApplicationStatus === LoanApplicationStatusEnum.quote_offered);
};

export const isValidQuote = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  if (!isQuote(loanApplicationStatus)) return false;

  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_offered ||
    loanApplicationStatus === LoanApplicationStatusEnum.quote_in_progress ||
    loanApplicationStatus === LoanApplicationStatusEnum.quote_in_review
  );
};

export const isApplicationInProgress = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return loanApplicationStatus === LoanApplicationStatusEnum.application_in_progress;
};

export const isValidApplication = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  if (!isApplication(loanApplicationStatus)) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.application_in_progress ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_successful
  );
};

export const isPaidOff = (
  loanApplicationStatus: LoanApplicationStatusEnum,
  loanArrearsStatus: LoanArrearsStatusEnum
) => {
  if (!loanApplicationStatus) return false;
  if (!loanArrearsStatus) return false;
  if (loanApplicationStatus !== LoanApplicationStatusEnum.application_successful) return false;
  return loanArrearsStatus === LoanArrearsStatusEnum.PAID_OFF;
};

export const isLoanArrearsCancelled = (loanArrearsStatus: LoanArrearsStatusEnum) => {
  if (!loanArrearsStatus) return false;
  return loanArrearsStatus === LoanArrearsStatusEnum.CANCELED;
};

export const isHistorical = (
  loanApplicationStatus: LoanApplicationStatusEnum,
  loanArrearsStatus: LoanArrearsStatusEnum,
  vaultLoanState: VaultLoanAccountStateEnum
) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_unsuccessful ||
    loanApplicationStatus === LoanApplicationStatusEnum.quote_cancelled ||
    loanApplicationStatus === LoanApplicationStatusEnum.quote_expired ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_unsuccessful ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_cancelled ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_expired ||
    isPaidOff(loanApplicationStatus, loanArrearsStatus) ||
    isLoanArrearsCancelled(loanArrearsStatus) ||
    vaultLoanState === VaultLoanAccountStateEnum.CANCELLED ||
    vaultLoanState === VaultLoanAccountStateEnum.PAID_OFF
  );
};

export const isCancelled = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_cancelled ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_cancelled
  );
};

export const isExpired = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_expired ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_expired
  );
};

export const isUnsuccessful = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_unsuccessful ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_unsuccessful
  );
};

export const isQuoteOffered = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return loanApplicationStatus === LoanApplicationStatusEnum.quote_offered;
};

export const isQuoteInReview = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return loanApplicationStatus === LoanApplicationStatusEnum.quote_in_review;
};

export const isApplicationSuccessful = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return loanApplicationStatus === LoanApplicationStatusEnum.application_successful;
};

export const isQuoteOfferedAndDebtConConsolidated = (
  loanApplication: LoanApplicationQueryResponse,
  userInstancesData: UserInstanceQueryResponse
) => {
  const {
    status,
    loanProduct: { name },
  } = loanApplication;
  if (!status || !name) return false;
  if (!isDebtCon(name)) return false;
  if (status === LoanApplicationStatusEnum.quote_offered) {
    const { incompleteTasks } = userInstancesData;
    return incompleteTasks?.some((task) => task.taskDefinitionId === 'user-task-affordability-approved');
  } else {
    return false;
  }
};

export const isVaultLoanStateWrittenOff = (vaultLoanState: VaultLoanAccountStateEnum) => {
  if (!vaultLoanState) return false;
  if (vaultLoanState === VaultLoanAccountStateEnum.WRITTEN_OFF) return true;
  else return false;
};

export const hasATerminalApplicationState = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !isValidQuote(loanApplicationStatus) && !isApplicationInProgress(loanApplicationStatus);
};
