import { generateIcon } from '../generateIcon';

export const FileUploadIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
        fill="#206EEE"
      />
      <path d="M17 9L12 4L7 9L8.4 10.45L11 7.85L11 16L13 16L13 7.85L15.6 10.45L17 9Z" fill="#206EEE" />
    </>
  ),
});
