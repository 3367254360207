import { generateIcon } from '../generateIcon';

export const ErrorCircleIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <circle cx="12" cy="12" r="12" fill="#D33D3B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.7891C13.1046 13.7891 14 12.8936 14 11.7891V5.78906C14 4.68449 13.1046 3.78906 12 3.78906C10.8954 3.78906 10 4.68449 10 5.78906V11.7891C10 12.8936 10.8954 13.7891 12 13.7891ZM12 19.4365C13.1046 19.4365 14 18.5411 14 17.4365C14 16.3319 13.1046 15.4365 12 15.4365C10.8954 15.4365 10 16.3319 10 17.4365C10 18.5411 10.8954 19.4365 12 19.4365Z"
        fill="white"
      />
    </>
  ),
});
