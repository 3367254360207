import { generateIcon } from '../generateIcon';

export const AddressCardIcon = generateIcon({
  viewBox: '0 0 30 30',
  variant: 'none',
  path: (
    <path
      d="M22.5 11.25H17.5M22.5 15H17.5M15 19.375C14.6455 17.9486 13.1013 16.875 11.25 16.875C9.39875 16.875 7.85454 17.9486 7.5 19.375M7.75 23.75H22.25C23.6501 23.75 24.3502 23.75 24.885 23.4775C25.3554 23.2378 25.7378 22.8554 25.9775 22.385C26.25 21.8502 26.25 21.1501 26.25 19.75V10.25C26.25 8.84987 26.25 8.1498 25.9775 7.61502C25.7378 7.14462 25.3554 6.76217 24.885 6.52248C24.3502 6.25 23.6501 6.25 22.25 6.25H7.75C6.34987 6.25 5.6498 6.25 5.11502 6.52248C4.64462 6.76217 4.26217 7.14462 4.02248 7.61502C3.75 8.1498 3.75 8.84987 3.75 10.25V19.75C3.75 21.1501 3.75 21.8502 4.02248 22.385C4.26217 22.8554 4.64462 23.2378 5.11502 23.4775C5.6498 23.75 6.34987 23.75 7.75 23.75ZM12.5 11.875C12.5 12.5654 11.9404 13.125 11.25 13.125C10.5596 13.125 10 12.5654 10 11.875C10 11.1846 10.5596 10.625 11.25 10.625C11.9404 10.625 12.5 11.1846 12.5 11.875Z"
      stroke="#206EEE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
