import { NetworthSourceKind } from './networth-source-kind.enum';

export class NetworthSourceDto {
  id: number;
  name: string;
  code: string;
  kind: NetworthSourceKind;
  description: string | null;
  display: boolean;
  displayOrder: number;
}

export enum NetworthSourceEnum {
  INCOME_SALARY_WAGES_ID = 1,
  INCOME_SELF_EMPLOYED_ID = 2,
  INCOME_RENT_ID = 3,
  INCOME_BENEFIT_ID = 16,
  INCOME_PENSION_ID = 18,
  INCOME_INTEREST_DIVIDEND_ID = 19,
  INCOME_SPOUSAL_SUPPORT_ID = 17,
  INCOME_CHILD_SUPPORT_ID = 32,
  INCOME_OTHER_ID = 4,
  INCOME_NO_ID = 15,
  INCOME_CHANGE_ID = 37,
  INCOME_PARTNER_ID = 44,
  INCOME_HOUSEHOLD_ID = 45,
  INCOME_SUPERANNUATION_ID = 39,
  INCOME_STUDENT_PAYMENTS_ID = 40,
  EXPENSE_RENT_ID = 31,
  EXPENSE_CHILD_SUPPORT_ID = 33,
  EXPENSE_BOARDER_ID = 34,
  EXPENSE_UTILITIES_ID = 5,
  EXPENSE_GROCERIES_ID = 6,
  EXPENSE_TRANSPORT_ID = 7,
  EXPENSE_INSURANCE_ID = 8,
  EXPENSE_MEDICAL_ID = 14,
  EXPENSE_FUEL_ID = 46,
  EXPENSE_HOUSEHOLD_ITEM_ID = 47,
  EXPENSE_INTERNET_ID = 48,
  EXPENSE_PET_CARE_ID = 49,
  EXPENSE_PHONE_PLAN_ID = 50,
  EXPENSE_POWER_ID = 51,
  EXPENSE_WATER_ID = 52,
  EXPENSE_NOT_LISTED_ID = 53,
  EXPENSE_LIVE_WITH_PARENTS_ID = 35,
  EXPENSE_OTHER_ID = 13,
  EXPENSE_NO_ID = 20,
  EXPENSE_CHANGE_ID = 38,
  EXPENSE_HOUSEHOLD_CHANGE_ID = 36,
  EXPENSE_GOVERNMENT_HOUSING_ID = 41,
  EXPENSE_COMPANY_HOUSING_ID = 42,
  EXPENSE_SHARED_ID = 43,
  LIABILITY_CREDIT_CARD_ID = 9,
  LIABILITY_PERSONAL_LOAN_ID = 10,
  LIABILITY_MORTGAGE_ID = 11,
  LIABILITY_OVERDRAFT_ID = 22,
  LIABILITY_BUY_NOW_PAY_LATER_ID = 30,
  LIABILITY_OTHER_ID = 12,
  LIABILITY_NO_ID = 21,
  LIABILITY_CAR_LOAN_ID = 54,
  LIABILITY_LINE_OF_CREDIT_ID = 56,
  ASSET_PROPERTY_LAND_ID = 23,
  ASSET_VEHICLE_ID = 24,
  ASSET_SAVINGS_ID = 25,
  ASSET_SHARES_BONDS_FUNDS_ID = 27,
  ASSET_BOAT_ID = 28,
  ASSET_OTHER_ID = 29,
  ASSET_NO_ID = 26,
}
