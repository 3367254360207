import { generateIcon } from '../generateIcon';

export const ReadOnlyIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_2490_20100)">
        <path
          d="M14 1H2C0.9 1 0 1.9 0 3V17H2V3H14V1ZM17 5H6C4.9 5 4 5.9 4 7V21C4 22.1 4.9 23 6 23H17C18.1 23 19 22.1 19 21V7C19 5.9 18.1 5 17 5ZM17 21H6V7H17V21Z"
          fill="#73A4F5"
        />
        <ellipse cx="18" cy="14" rx="4" ry="3" fill="white" />
        <path
          d="M18 10C15.2727 10 12.9436 11.6587 12 14C12.9436 16.3413 15.2727 18 18 18C20.7273 18 23.0564 16.3413 24 14C23.0564 11.6587 20.7273 10 18 10ZM18 16.6667C16.4945 16.6667 15.2727 15.472 15.2727 14C15.2727 12.528 16.4945 11.3333 18 11.3333C19.5055 11.3333 20.7273 12.528 20.7273 14C20.7273 15.472 19.5055 16.6667 18 16.6667ZM18 12.4C17.0945 12.4 16.3636 13.1147 16.3636 14C16.3636 14.8853 17.0945 15.6 18 15.6C18.9055 15.6 19.6364 14.8853 19.6364 14C19.6364 13.1147 18.9055 12.4 18 12.4Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2490_20100">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
