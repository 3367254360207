import { DashboardIcon, Divider, Label, SignOutIcon } from '@harmoney/ui-design-system';

import styles from './NavBar.module.scss';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useAuth } from '../../../../../../apps/journey/hooks/use-auth'; // TODO - Fix import
import { UserDto } from '@harmoney/api-interfaces';
import { Icon } from '@iconify/react';

enum NavPath {
  Dashboard = '/dashboard',
  Loans = '/loans',
  // MyProfile = '/profile',
  SignOut = '/signout',
}

export interface MenuItem {
  id: string;
  label: string;
  icon: React.ReactElement;
  path: NavPath;
}

const MenuItems: MenuItem[] = [
  {
    id: 'dashboard',
    label: 'My dashboard',
    icon: <DashboardIcon />,
    path: NavPath.Dashboard,
  },
  {
    id: 'loans',
    label: 'My loans',
    icon: <Icon icon="ic:baseline-content-copy" className="text-grey-3" width={26} height={26} />,
    path: NavPath.Loans,
  },
  // TODO - Uncomment when profile page is ready
  // {
  //   id: 'profile',
  //   label: 'My profile',
  //   icon: <ProfileIcon />,
  //   path: NavPath.MyProfile,
  // },
  {
    id: 'sign_out',
    label: 'Sign out',
    icon: <SignOutIcon />,
    path: NavPath.SignOut,
  },
];

function getEnumFromValue(value: string): NavPath | undefined {
  for (const key in NavPath) {
    if (value.includes(NavPath[key as keyof typeof NavPath])) {
      return NavPath[key as keyof typeof NavPath];
    }
  }
  return undefined;
}

export interface NavBarProps {
  handleToggle: () => void;
  user?: UserDto;
}
export const NavBar = ({ handleToggle, user }: NavBarProps) => {
  const { logout, isAuthenticated, user: authUser, getUserRoles } = useAuth();
  const [selectedItem, setSelectedItem] = useState<string>(NavPath.Dashboard);
  const [isMenuClicked, setMenuClicked] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);

  const router = useRouter();
  const path = router.asPath;

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    event.preventDefault();
    setSelectedItem(id);
    setMenuClicked(true);
  };

  useEffect(() => {
    const navPath = getEnumFromValue(path);
    MenuItems.filter((item) => item.path === navPath).forEach((item) => setSelectedItem(item.id));
    if (selectedItem === 'sign_out') {
      handleToggle();
      logout();
    }
    if (isMenuClicked) {
      setMenuClicked(false);
      handleToggle();
    }
  }, [path, selectedItem]);

  useEffect(() => {
    if (authUser) {
      setRoles(getUserRoles(authUser));
    }
  }, [authUser, getUserRoles]);

  return (
    <nav className="shadow-drop fixed z-20 h-full w-full overflow-scroll bg-white md:w-[25rem]">
      {isAuthenticated && (
        <>
          <div className="px-12 py-6">
            <div className="text-sm font-medium"> {user?.preferredName}</div>
            <div className="text-grey-4 text-xs">{user?.email}</div>
          </div>
          <Divider className="text-grey-2 m-0" />
        </>
      )}

      <ul className=" flex min-h-[450px] w-full cursor-pointer list-none flex-col	overflow-y-scroll py-8 px-4 ">
        {isAuthenticated && roles.includes('admin') && (
          <li className={path.includes('admin') ? styles['selected'] : ''} onClick={() => setMenuClicked(true)}>
            <Link href={'/admin'} rel="noreferrer noopener" className={styles['li-container']}>
              <span className={styles['svg-container']}>
                <DashboardIcon />
              </span>
              <Label className="cursor-pointer">Admin portal</Label>
            </Link>
          </li>
        )}
        {MenuItems.map((item) => {
          return (
            <li
              className={selectedItem === item.id ? styles['selected'] : ''}
              onClick={(e) => handleClick(e, item.id)}
              id={item.id}
              key={item.id}
            >
              <Link
                href={item.path === NavPath.SignOut ? '/' : item.path}
                rel="noreferrer noopener"
                className={styles['li-container']}
              >
                <span className={styles['svg-container']}>{item.icon}</span>
                <Label className="cursor-pointer">{item.label}</Label>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
