import { SaveAssetsDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const assetApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    submitAsset: builder.mutation<void, SaveAssetsDto>({
      query: (data) => {
        return {
          url: `/financial-profile/assets`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useSubmitAssetMutation } = assetApi;
