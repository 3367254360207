import { useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetUserQuery } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Card, ControlledInput, Form, useForm } from '@harmoney/ui-design-system';
import PasswordValidator from 'password-validator';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import styles from './PasswordSetup.module.scss';

export const passwordSchema = (password: string, blackListedValue: string[]) => {
  const schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length of 8 characters
    .is()
    .max(20) // Maximum length of 20 characters
    .has()
    .uppercase() // Must contain uppercase letter
    .has()
    .lowercase() // Must contain lowercase letter
    .has()
    .digits() // Must contain at least one digit
    .has()
    .symbols() // Must contain at least one special character
    .has()
    .not()
    .spaces() // Must not contain spaces
    .has()
    .not()
    .oneOf(blackListedValue); // Must not contain black listed Value

  return schema.validate(password, { list: true });
};

const passwordRules = [
  { text: 'At least 8 characters', keyword: ['min', 'max'] },
  { text: 'At least 1 lowercase and uppercase letter', keyword: ['uppercase', 'lowercase'] },
  { text: 'At least 1 number', keyword: ['digits'] },
  { text: 'At least 1 special character', keyword: ['symbols'] },
];

export function PasswordSetup({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [errorMessage, setErrorMessage] = useState<string[]>();
  const { data: userData } = useGetUserQuery();
  const blackListedValue = [userData?.email, userData?.preferredName];

  const formSchema = z.object({
    password: z.string().refine(
      (password) => {
        const validationErrors: any[] | boolean = passwordSchema(password, blackListedValue);
        Array.isArray(validationErrors) && setErrorMessage(validationErrors);
        return Array.isArray(validationErrors) ? validationErrors?.length === 0 : !validationErrors;
      },
      {
        message: `Your password doesn't meet our criteria`,
      }
    ),
  });

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const handleSubmit = () => {
    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <div className="flex flex-col ">
      <h1 className="text-grey-5">
        Choose a <span className="text-primary">password</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <ControlledInput className="mb-2" {...register('password')} label="Please create a password" type="text" />
          <div>
            <span className="mb-1 pt-2 text-xs">Your password should include:</span>
            <ul className={styles['custom-bullet']}>
              {passwordRules.map(({ text, keyword }, index) => (
                <li
                  key={index}
                  className={
                    errorMessage && errorMessage.some((e) => keyword.includes(e))
                      ? styles['invalid']
                      : errorMessage && styles['valid']
                  }
                >
                  {text}
                </li>
              ))}
            </ul>
          </div>
        </Card>

        <Button
          className="flex"
          type="submit"
          alignIcon="end"
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          icon={<ArrowCircleRightIcon size="large" />}
          hasShadow
        >
          Save password
        </Button>
      </Form>
    </div>
  );
}

export default PasswordSetup;
