import { useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  AddressCardIcon,
  ArrowCircleRightIcon,
  Button,
  Card,
  ClockIcon,
  UserCheckIcon,
} from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export function Welcome({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Just <span className="text-primary">a few things</span> before we continue
      </h1>

      <Card>
        <div className="flex items-center">
          <ClockIcon size="large" variant="secondary" />
          <p className="pl-4">This should only take a few minutes.</p>
        </div>
      </Card>

      <div className="my-6">
        <h2 className="text-base font-bold">You&rsquo;ll need your:</h2>
        <ul>
          <li className="mb-2 flex items-center">
            <AddressCardIcon size="medium" variant="secondary" />
            <p className="px-4">Australian passport or driver&rsquo;s licence</p>
          </li>

          <li className="flex items-center">
            <UserCheckIcon size="medium" />
            <p className="px-4">Bank account details</p>
          </li>
        </ul>
      </div>

      <div className="my-6">
        <h2 className="my-4 text-base font-bold">Why do we need these?</h2>
        <p>
          In order to comply with the law, we&rsquo;re required to gather some basic information about you. We will keep
          your personal information safe and private.
        </p>
      </div>

      <div className="flex justify-center">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          hasShadow
        >
          I&rsquo;m ready, continue
        </Button>
      </div>
    </>
  );
}
