import { useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  ClockLightningIcon,
  InformationBox,
  MobileFriendlyIcon,
  ValidIdIcon,
} from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

const informationBoxItems = [
  {
    icon: <ValidIdIcon />,
    text: 'You’ll need a 2nd form of ID such as Australian Passport or Foreign Passport',
  },
  {
    icon: <MobileFriendlyIcon />,
    text: "Make sure your phone's camera is working so you can take a selfie ",
  },
  {
    icon: <ClockLightningIcon />,
    text: '5 min of your time to complete this step',
  },
];

export function VevoWelcome({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    await completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Before you <span className="text-primary">continue</span>
      </h1>

      <p>
        We take security seriously, which is why we ask for verification of your identity before deciding on your loan
        application.
      </p>

      <Card className="!p-0">
        <InformationBox title="You’ll need:" items={informationBoxItems} />
      </Card>

      <div className="flex justify-center">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          hasShadow
        >
          I&rsquo;m ready, continue
        </Button>
      </div>
    </>
  );
}
