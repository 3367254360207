import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { errors } from '@harmoney/ui-utils';
import { isBooleanStringFalse, isBooleanStringTrue, isValidBooleanString } from '@harmoney/utilities';
import { AssetAndLiabilityRecordOriginEnum } from '@prisma/client';
import { isEmpty } from 'lodash';
import { z } from 'zod';

export const prefillSchema = z.object({
  paidOff: z.string().min(1, { message: errors.defaultRequiredField }).optional(),
});

export const providerSchemaBase = z
  .object({
    provider: z.string().min(1, { message: errors.defaultRequiredField }),
    otherProvider: z.string().optional().nullable(),
  })
  .refine(
    (data) => {
      if (data.provider === 'other' && isEmpty(data.otherProvider)) {
        return false;
      }

      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['otherProvider'],
    }
  );

export const debtSchemaBase = z
  .object({
    id: z.string().optional(),
    repaymentAmount: z
      .number({ invalid_type_error: errors.defaultValidAmount })
      .gte(1, { message: errors.defaultValidAmount })
      .nullable()
      .optional(),
    frequency: z.string().optional().nullable(),
    recordOrigin: z.nativeEnum(AssetAndLiabilityRecordOriginEnum).default(AssetAndLiabilityRecordOriginEnum.manual),
    creditFileReference: z.string().optional().nullable(),
  })
  .partial()
  .and(providerSchemaBase)
  .refine(
    (data) => {
      if (data.repaymentAmount > 0 && isEmpty(data.frequency) && data.recordOrigin !== 'prefill') return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );

export const debtSchemaForMortgage = z
  .intersection(
    debtSchemaBase,
    z.object({
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_MORTGAGE_ID),
      isResidence: z
        .string({ required_error: errors.defaultRequiredField })
        .min(1, { message: errors.defaultRequiredField })
        .nullable()
        .optional(),
      isMortgageShared: z.boolean({ required_error: errors.defaultRequiredField }).optional().nullable(),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .nullable()
        .optional(),
      isJointMortgageFromCreditFile: z.boolean().nullish().nullable(),
      rentalIncomeCoversMortgage: z.string({ required_error: errors.defaultRequiredField }).optional().nullable(),
    })
  )
  .and(providerSchemaBase)
  .and(prefillSchema)
  .superRefine((data, ctx) => {
    const { addIssue } = ctx;
    if (data.recordOrigin === 'prefill') {
      if (data.paidOff === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['paidOff'],
        });
        return;
      }
      if (isBooleanStringFalse(data.paidOff)) {
        if (!data.repaymentAmount) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['repaymentAmount'],
          });
        }
        if (!data.frequency) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['frequency'],
          });
        }
        if (data.isResidence === undefined) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredField,
            path: ['isResidence'],
          });
        }
        if (isBooleanStringFalse(data.isResidence)) {
          if (!data.rentalIncomeCoversMortgage) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.defaultRequiredField,
              path: ['rentalIncomeCoversMortgage'],
            });
          }
        }
        if (!data.outstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['outstandingBalance'],
          });
        }
        return ctx;
      } else {
        data.repaymentAmount = null;
        data.frequency = null;
        data.outstandingBalance = null;
        data.isMortgageShared = null;
        data.isResidence = null;
        return ctx;
      }
    } else {
      if (data.isResidence === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['isResidence'],
        });
      }
      if (isBooleanStringFalse(data.isResidence)) {
        if (!data.rentalIncomeCoversMortgage) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredField,
            path: ['rentalIncomeCoversMortgage'],
          });
        }
      }
      if (!data.outstandingBalance) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['outstandingBalance'],
        });
      }
    }
  });

export const debtSchemaForSharedMortgage = z
  .intersection(
    debtSchemaBase,
    z.object({
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_MORTGAGE_ID),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional()
        .nullable(),
      isResidence: z
        .string({ required_error: errors.defaultRequiredField })
        .min(1, { message: errors.defaultRequiredField })
        .optional()
        .nullable(),
      rentalIncomeCoversMortgage: z.string({ required_error: errors.defaultRequiredField }).optional().nullable(),
      isJointMortgage: z.string({ required_error: errors.defaultRequiredField }).optional().nullable(),
      jointMortgageAmount: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount })
        .optional()
        .nullable(),
      jointMortgageFrequency: z.string().optional().nullable(),
      isJointMortgageFromCreditFile: z.boolean().nullish().nullable(),
    })
  )
  .and(prefillSchema)
  .superRefine((data, ctx) => {
    const { addIssue } = ctx;
    const addErrors = () => {
      if (!data.repaymentAmount) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultValidAmount,
          path: ['repaymentAmount'],
        });
      }
      if (!data.frequency) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredFrequency,
          path: ['frequency'],
        });
      }
      if (isValidBooleanString(data.isJointMortgage) || isValidBooleanString(data.rentalIncomeCoversMortgage)) {
        if (!data.outstandingBalance) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['outstandingBalance'],
          });
        }
      }
      if (data.isResidence === undefined || data.isResidence === null) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['isResidence'],
        });
      }
      if (isBooleanStringTrue(data.isResidence)) {
        if (!data.isJointMortgage) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredField,
            path: ['isJointMortgage'],
          });
        }
      }
      if (isBooleanStringFalse(data.isResidence)) {
        if (!data.rentalIncomeCoversMortgage) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredField,
            path: ['rentalIncomeCoversMortgage'],
          });
        }
      }
      if (isBooleanStringTrue(data.isJointMortgage)) {
        if (!data.jointMortgageAmount) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultValidAmount,
            path: ['jointMortgageAmount'],
          });
        }
        if (!data.jointMortgageFrequency) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['jointMortgageFrequency'],
          });
        }
      }
      if (isBooleanStringFalse(data.isJointMortgage)) {
        data.jointMortgageAmount = null;
        data.jointMortgageFrequency = null;
      }
      return ctx;
    };
    if (data.recordOrigin === 'prefill') {
      if (data.paidOff === undefined) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.defaultRequiredField,
          path: ['paidOff'],
        });
        return ctx;
      }
      if (isBooleanStringFalse(data.paidOff)) {
        addErrors();
        return ctx;
      } else {
        data.repaymentAmount = null;
        data.frequency = null;
        data.outstandingBalance = null;
        data.rentalIncomeCoversMortgage = null;
        data.isJointMortgage = null;
        data.jointMortgageAmount = null;
        data.jointMortgageFrequency = null;
        return ctx;
      }
    } else {
      addErrors();
    }
  });

export const debtSchemaForBuyNowPayLater = z
  .intersection(
    debtSchemaBase,
    z.object({
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_BUY_NOW_PAY_LATER_ID),
      creditLimit: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount }),
    })
  )
  .refine(
    (data) => {
      if (data?.creditLimit < data?.repaymentAmount) {
        return false;
      }

      return true;
    },
    {
      message: errors.invalidCreditLimit,
      path: ['creditLimit'],
    }
  )
  .and(providerSchemaBase);

export const debtSchemaForOther = z
  .intersection(
    debtSchemaBase,
    z.object({
      networthSourceId: z.literal(NetworthSourceEnum.LIABILITY_OTHER_ID),
      debtType: z.string().min(1, { message: errors.requiredField('Kind of debt') }),
      outstandingBalance: z
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(1, { message: errors.defaultValidAmount }),
    })
  )
  .and(providerSchemaBase);
