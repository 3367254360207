import { generateIcon } from '../generateIcon';

export const StarIcon = generateIcon({
  viewBox: '0 0 57 57',
  path: (
    <>
      <g clipPath="url(#clip0_25_232)">
        <path
          d="M34.2714 23.75L30.7802 12.255C30.0914 9.99874 26.9089 9.99874 26.2439 12.255L22.7289 23.75H12.1602C9.85643 23.75 8.90643 26.7187 10.7827 28.0487L19.4277 34.2237L16.0314 45.1725C15.3427 47.3812 17.9077 49.1625 19.7364 47.7612L28.5002 41.1112L37.2639 47.785C39.0927 49.1862 41.6577 47.405 40.9689 45.1962L37.5727 34.2475L46.2177 28.0725C48.0939 26.7187 47.1439 23.7737 44.8402 23.7737H34.2714V23.75Z"
          fill="url(#paint0_linear_25_232)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_25_232"
          x1="47.2142"
          y1="29.4226"
          x2="9.78613"
          y2="29.4226"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4B4A" />
          <stop offset="1" stopColor="#FF8D8C" />
        </linearGradient>
        <clipPath id="clip0_25_232">
          <rect width="57" height="57" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
