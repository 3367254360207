import Link from 'next/link';
import { loanStatusBadgeMapper, loanStatusMapper, opportunityMapper } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { Badge } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { useHash } from '@mantine/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';

interface CustomerApplicationListItemProps {
  loanApplication: LoanApplicationQueryResponse & {
    children?: LoanApplicationQueryResponse[];
    unutilisedLimitEligibility?: { isEligible: boolean; adminPortalMessage?: string; reason: string };
  };
}

export const CustomerApplicationListItem = ({ loanApplication }: CustomerApplicationListItemProps) => {
  const [hash, setHash] = useHash();
  const loanPurpose = loanApplication.loanApplicationPurposes
    ?.map((loanApplicationPurpose) => loanApplicationPurpose?.loanPurpose?.displayName)
    .join(', ');

  const isChildRecord = loanApplication.originalLoanApplicationId !== null;
  const showSuccessAlert =
    loanApplication.unutilisedLimitEligibility?.isEligible ||
    (!loanApplication.unutilisedLimitEligibility?.isEligible &&
      loanApplication.unutilisedLimitEligibility?.reason === 'UL in progress');
  const renderAdminPortalMessage = (copy: string) => {
    return copy.replace(/\[/g, '<span class="font-medium">').replace(/\]/g, '</span>');
  };
  return (
    <>
      {loanApplication.unutilisedLimitEligibility?.adminPortalMessage && (
        <div
          className={classNames(
            {
              'bg-tertiary-lighter-2': showSuccessAlert,
            },
            'p-4 pl-4 text-sm flex items-center'
          )}
        >
          {!showSuccessAlert && <Icon icon="ic:round-info" className="text-grey-3 mr-2" width={18} />}
          <span
            dangerouslySetInnerHTML={{
              __html: renderAdminPortalMessage(loanApplication.unutilisedLimitEligibility.adminPortalMessage),
            }}
          />
        </div>
      )}
      <li
        key={loanApplication.id}
        className={`border-grey-2 grid grid-cols-10 p-4 text-sm ${classNames({ 'py-0 mt-2 pb-4': isChildRecord })}`}
      >
        {!isChildRecord ? (
          <div className="col-span-2 ml-3">
            <span
              title={`View Application ${loanApplication.id}`}
              onClick={() => setHash(`applications/${loanApplication.id}`)}
              className="cursor-pointer text-secondary"
            >
              {loanApplication.id}
            </span>
          </div>
        ) : (
          <div className="col-span-2 ml-4 flex flex-row">
            <div className="w-6 h-6 border-l-2 border-b-2 border-grey-2 -mt-2 rounded-bl-md"></div>
            <span
              title={`View Application ${loanApplication.id}`}
              onClick={() => setHash(`applications/${loanApplication.id}`)}
              className=" cursor-pointer text-secondary mt-1 ml-2"
            >
              {loanApplication.id}
            </span>
          </div>
        )}
        <div className="col-span-1 ml-3">
          <span>{loanApplication.loanProduct?.name}</span>
        </div>
        <div className="col-span-1 ml-3">
          {loanApplication.originationType ? (
            <span>{opportunityMapper[loanApplication.originationType]}</span>
          ) : (
            <span>New loan</span>
          )}
        </div>
        <div className="col-span-1 ml-3">
          <span>{loanPurpose}</span>
        </div>
        <div className="col-span-1 ml-4">
          <span>{formatCurrency(loanApplication.fundedAmount || loanApplication.requestedAmount)}</span>
        </div>
        <div className="col-span-1 ml-3">
          <span>{dayjs(loanApplication.createdAt).format(DATE_FORMAT)}</span>
        </div>
        <div className="col-span-1 ml-2">
          {loanApplication.status === 'application_successful' ? (
            <Link
              title={`View Loan ${loanApplication.id}`}
              href={`/admin/customer/loan?applicationId=${loanApplication.id}&userId=${loanApplication.userId}`}
              className="cursor-pointer text-secondary"
            >
              <span>{loanApplication.businessKey}</span>
            </Link>
          ) : (
            <span>{loanApplication.businessKey}</span>
          )}
        </div>
        <div className="col-span-2 ml-6">
          <Badge
            variant={loanStatusBadgeMapper[loanApplication?.status] ?? 'tertiary'}
            label={loanStatusMapper[loanApplication?.status] ?? 'N/A'}
          />
        </div>
      </li>
      {loanApplication.children?.map((child) => <CustomerApplicationListItem key={child.id} loanApplication={child} />)}
    </>
  );
};
