import { Store } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';

let store: Store<RootState>;

export const injectStore = (_store) => {
  store = _store;
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_BFF_API_URL,
  prepareHeaders: (headers) => {
    const accessToken = store.getState().accessToken.value;
    headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});

export const emptySplitApi = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    'User',
    'Task',
    'Application',
    'LoanDocument',
    'CreditAudit',
    'EssentialExpense',
    'ApplicationCount',
    'AuditDecision',
    'BankStatementReference',
    'InterestRateAdjustments',
    'ReprocessOperation',
    'FinancialProfile',
    'InternalTask',
    'Search',
    'RepeatEligibility',
  ],
  endpoints: () => ({}),
});
