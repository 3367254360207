import { generateIcon } from '../generateIcon';

export const CheckSquareIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <rect width="24" height="24" rx="4" fill="#45D06C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1748 16.72C9.82526 16.7235 9.47458 16.5914 9.20787 16.3238L5.39485 12.4977C4.86838 11.9694 4.86838 11.1129 5.39485 10.5847C5.92132 10.0564 6.77489 10.0564 7.30136 10.5847L10.1627 13.4558L16.2015 7.39621C16.728 6.86793 17.5816 6.86793 18.108 7.39621C18.6345 7.92448 18.6345 8.78098 18.108 9.30926L11.1175 16.3238C10.857 16.5852 10.5163 16.7173 10.1748 16.72Z"
        fill="white"
      />
    </>
  ),
});
