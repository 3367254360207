import { useEffect } from 'react';
import { useAppSelector, useGetSmartUIParameterQuery } from '@harmoney/redux';
import { eventAnalytics, SMART_UI_LOADED_EVENT } from '@harmoney/ui-app-shell';
import { BranchEnum as Branch } from '@prisma/client';

import {
  FRANKIE_ONFIDO_CONFIGURATION_AU,
  FRANKIE_ONFIDO_CONFIGURATION_NZ,
  FRANKIE_VEVO_CONFIGURATION_AU,
  FRANKIE_VEVO_CONFIGURATION_NZ,
} from '../components/fraud-prevention';
import { FRANKIE_CONFIGURATION_AU, FRANKIE_CONFIGURATION_NZ } from '../components/identity';

import { useBranch } from './use-branch';

export enum SmartUIConfigType {
  ONFIDO = 'ONFIDO',
  VEVO = 'VEVO',
  DEFAULT = 'DEFAULT',
}

const getConfig = (branch: Branch, configType: SmartUIConfigType) => {
  const configurations = {
    [Branch.NZ]: {
      [SmartUIConfigType.ONFIDO]: FRANKIE_ONFIDO_CONFIGURATION_NZ,
      [SmartUIConfigType.VEVO]: FRANKIE_VEVO_CONFIGURATION_NZ,
      [SmartUIConfigType.DEFAULT]: FRANKIE_CONFIGURATION_NZ,
    },
    [Branch.AU]: {
      [SmartUIConfigType.ONFIDO]: FRANKIE_ONFIDO_CONFIGURATION_AU,
      [SmartUIConfigType.VEVO]: FRANKIE_VEVO_CONFIGURATION_AU,
      [SmartUIConfigType.DEFAULT]: FRANKIE_CONFIGURATION_AU,
    },
  };

  return configurations[branch][configType] || configurations[branch][SmartUIConfigType.DEFAULT];
};

const getEvent = (taskId: string, configType: SmartUIConfigType) => {
  const defaultEvent = {
    name: SMART_UI_LOADED_EVENT,
    data: {
      taskid_str: taskId,
      spoke_name_str: 'IDV',
    },
  };

  const events = {
    [SmartUIConfigType.ONFIDO]: {
      ...defaultEvent,
      name: SMART_UI_LOADED_EVENT,
    },
    [SmartUIConfigType.VEVO]: {
      ...defaultEvent,
      name: SMART_UI_LOADED_EVENT,
    },
    [SmartUIConfigType.DEFAULT]: defaultEvent,
  };

  return events[configType] || events[SmartUIConfigType.DEFAULT];
};

export const useInitialiseFrankieSmartUI = (taskId: string, configType: SmartUIConfigType) => {
  const userId = useAppSelector((state) => state.userId.value);
  const branch = useBranch();
  const { data: smartUIParameters, refetch: refetchSmartUIParameter } = useGetSmartUIParameterQuery(
    { userId, branch },
    { skip: !userId || !branch }
  );

  useEffect(() => {
    const initialiseWidget = (branch: Branch) => {
      const { frankieBackendUrl, token, email, mobilePhoneNumber } = smartUIParameters;
      const config = getConfig(branch, configType);

      (window as any).frankieFinancial.initialiseOnboardingWidget({
        applicantReference: userId,
        ffToken: token,
        width: 'AUTO',
        height: 'AUTO',
        config: {
          frankieBackendUrl,
          preload: {
            email,
            phone: mobilePhoneNumber,
          },
          ...config,
        },
      });

      if (taskId) {
        const { name, data } = getEvent(taskId, configType);
        eventAnalytics.track(name, data);
      }
    };

    if ((window as any).frankieFinancial && userId && smartUIParameters && branch && taskId && configType) {
      initialiseWidget(branch);
    }
  }, [userId, smartUIParameters, branch, taskId, configType]);

  useEffect(() => {
    const handleInitialised = (detail) => {
      if (detail?.detail?.sessionId === 'no-session') {
        refetchSmartUIParameter();
      }
    };

    window.addEventListener('INIT', handleInitialised);

    return () => {
      window.removeEventListener('INIT', handleInitialised);
    };
  }, [refetchSmartUIParameter, taskId, userId]);

  return { userId, branch };
};
