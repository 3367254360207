import { useEffect, useState } from 'react';
import { LoanProductPurposeDto, LoanPurposeDisplayType } from '@harmoney/api-interfaces';
import { CommonOptionProps } from '@harmoney/ui-design-system';
import { kebabCase } from 'lodash';

export const useTransformLoanPurposes = (loanPurposes: LoanProductPurposeDto[] = [], removeDebtCon = false) => {
  const [transformedLoanPurposes, setTransformedLoanPurposes] = useState({
    primaryLoanPurposes: [],
    secondaryLoanPurposes: [],
  });

  useEffect(() => {
    if (loanPurposes.length === 0) return;

    const { primaryLoanPurposes, secondaryLoanPurposes } = transformLoanPurposes(loanPurposes, removeDebtCon);
    setTransformedLoanPurposes({
      primaryLoanPurposes,
      secondaryLoanPurposes,
    });
  }, [loanPurposes]);

  return transformedLoanPurposes;
};

const transformLoanPurposes = (loanPurposes: LoanProductPurposeDto[] = [], removeDebtCon: boolean) => {
  const {
    primaryLoanPurposes,
    secondaryLoanPurposes,
  }: {
    primaryLoanPurposes: CommonOptionProps[];
    secondaryLoanPurposes: CommonOptionProps[];
  } =
    loanPurposes.length > 0 &&
    loanPurposes.reduce(
      (transformedLoanPurpose, { id, name, displayName, displayType, subtitle, description }) => {
        if (name === 'debt consolidation' && !removeDebtCon) {
          transformedLoanPurpose.primaryLoanPurposes.push({
            label: displayName,
            value: id,
            icon: `hmy:${kebabCase(name)}`,
            iconSelected: `hmy:${kebabCase(name)}-selected`,
            subtitle,
            description,
          });
          return transformedLoanPurpose;
        }
        if (name === 'going on holiday' && removeDebtCon) {
          transformedLoanPurpose.primaryLoanPurposes.push({
            label: displayName,
            value: id,
            iconName: kebabCase(name),
            subtitle,
            description,
          });
          return transformedLoanPurpose;
        }
        if (displayType === LoanPurposeDisplayType.ICON && name !== 'debt consolidation') {
          transformedLoanPurpose.primaryLoanPurposes.push({
            label: displayName,
            value: id,
            iconName: kebabCase(name),
            subtitle,
            description,
          });
          return transformedLoanPurpose;
        }
        if (displayType === LoanPurposeDisplayType.SELECT) {
          transformedLoanPurpose.secondaryLoanPurposes.push({
            label: displayName,
            value: id,
            iconName: kebabCase(name),
            subtitle,
            description,
          });
          return transformedLoanPurpose;
        }
        return transformedLoanPurpose;
      },
      { primaryLoanPurposes: [], secondaryLoanPurposes: [] }
    );

  return {
    primaryLoanPurposes,
    secondaryLoanPurposes: [
      {
        label: 'More loan purposes',
        value: '',
        iconName: 'more-loan-purposes',
      },
      ...secondaryLoanPurposes,
    ],
  };
};
