import { generateIcon } from '../generateIcon';

export const CalendarIcon = generateIcon({
  viewBox: '0 0 24 24',
  variant: 'none',
  path: (
    <>
      <g clipPath="url(#clip0_1702_17360)">
        <path d="M22 3H19V1H17V3H7V1H5V3H2V23H22V3ZM20 21H4V8H20V21Z" fill="#8E8E8E" />
      </g>
      <defs>
        <clipPath id="clip0_1702_17360">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
