import { generateIcon } from '../generateIcon';

export const CheckCircleIconBlue = generateIcon({
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M7.99967 1.83337C4.31967 1.83337 1.33301 4.82004 1.33301 8.50004C1.33301 12.18 4.31967 15.1667 7.99967 15.1667C11.6797 15.1667 14.6663 12.18 14.6663 8.50004C14.6663 4.82004 11.6797 1.83337 7.99967 1.83337ZM6.66634 11.8334L3.33301 8.50004L4.27301 7.56004L6.66634 9.94671L11.7263 4.88671L12.6663 5.83337L6.66634 11.8334Z"
      fill="#206EEE"
    />
  ),
});
