import { IsNotEmpty } from 'class-validator';
import { UserInstanceState } from './user-instance-state';
import { OriginationType } from './origination-type';

export class CreateUserInstanceDto {
  @IsNotEmpty()
  userId: string;

  productId?: string;
  processId?: string;
  bpmnProcessId?: string;
  version?: number;
  instanceId?: string;
  parentInstanceId?: string;
  state?: UserInstanceState;
  spokeVariables?: { [key: string]: string | number };
  originalLoanApplicationId?: string;
  originationType?: OriginationType;
}
