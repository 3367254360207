import { KeyValuePair } from '../event-analytics';
import * as FullStory from '@fullstory/browser';

/**
 * This is the concrete implementation of FullStory API.
 * Reference: https://www.npmjs.com/package/@fullstory/browser
 */
export class FullStoryAnalytics {
  load() {
    const orgId = process.env['NEXT_PUBLIC_FULLSTORY_ORG_ID'] || '';

    if (orgId && !FullStory.isInitialized()) {
      FullStory.init({ orgId });
    }
  }

  identify(userId: string, traits?: KeyValuePair) {
    if (!FullStory.isInitialized()) return;

    try {
      const userVars: KeyValuePair = { ...traits };
      if (traits && 'name' in traits) {
        userVars['displayName'] = traits['name'];
        delete userVars['name'];
      }
      FullStory.identify(userId, userVars);
    } catch (e) {
      console.error(e);
    }
  }

  track(event: string, properties?: KeyValuePair) {
    if (!FullStory.isInitialized()) return;

    try {
      FullStory.event(event, properties || {});
    } catch (e) {
      console.error(e);
    }
  }
}
