import { generateIcon } from '../generateIcon';

export const AppleIcon = generateIcon({
  viewBox: '0 0 32 32',
  path: (
    <>
      <g transform="translate(3) scale(.03677)" fill="#141414">
        <defs>
          <path id="a" d="M0 0h708.7v870.3H0z" />
          <path
            id="c"
            d="M591.8 462.4c-1-110.1 90-163 94.1-165.6-51.2-74.9-130.8-85.1-159.2-86.3-67.7-6.9-132.2 39.9-166.6 39.9s-87.4-38.9-143.6-37.8c-73.9 1.1-142 42.9-180.1 109.1-76.7 133-19.6 330.3 55.2 438.4 36.6 52.8 80.1 112.3 137.4 110.1 55.2-2.2 76-35.7 142.6-35.7s85.4 35.7 143.6 34.6c59.3-1.2 96.9-54 133.2-107 41.9-61.3 59.2-120.7 60.2-123.8-1.3-.5-115.6-44.3-116.8-175.9"
          />
        </defs>
        <clipPath id="b">
          <use xlinkHref="#a" />
        </clipPath>
        <g clipPath="url(#b)">
          <use xlinkHref="#c" />
        </g>
        <path d="M482.3 139c30.4-36.8 50.9-88 45.3-139-43.8 1.8-96.8 29.2-128.2 66-28.1 32.5-52.8 84.6-46.1 134.6 48.7 3.8 98.6-24.9 129-61.6" />
      </g>
      <g clipPath="url(#b)" transform="matrix(.09852 0 0 .09852 89.58 -18.77)">
        <use xlinkHref="#c" />
      </g>
    </>
  ),
});
