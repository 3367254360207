import { useFriendlyURL } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

import styles from './ConnectBank.module.scss';

/* eslint-disable-next-line */
export interface ConnectBankProps extends CommonProps {}

export function ConnectBank({ taskId, completeTask, taskFriendlyURL }: ConnectBankProps) {
  const handleSubmit = () => {
    completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <div className={styles['container']}>
      <h1>Welcome to ConnectBank!</h1>
      <Button
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        variant="primary"
        onClick={handleSubmit}
        hasShadow
      >
        Continue
      </Button>
    </div>
  );
}

export default ConnectBank;
