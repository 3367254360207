import { generateIcon } from '../generateIcon';

export const DeleteIcon = generateIcon({
  viewBox: '0 0 16 20',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 1.5H15.5V4H0.5V1.5H4.25L5.32143 0.25H10.6786L11.75 1.5ZM3.5 19.75C2.25736 19.75 1.25 18.7906 1.25 17.6071V5.5H14.75V17.6071C14.75 18.7906 13.7426 19.75 12.5 19.75H3.5Z"
        fill="#206EEE"
      />
      <mask id="mask0_3037_1078" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.75 1.5H15.5V4H0.5V1.5H4.25L5.32143 0.25H10.6786L11.75 1.5ZM3.5 19.75C2.25736 19.75 1.25 18.7906 1.25 17.6071V5.5H14.75V17.6071C14.75 18.7906 13.7426 19.75 12.5 19.75H3.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3037_1078)">
        <rect x="-1.75" y="0.25" width="19.5" height="19.5" fill="#206EEE" />
      </g>
    </>
  ),
});
