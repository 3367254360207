import { useMemo } from 'react';
import { useBranch, useFriendlyURL } from '@harmoney/hooks';
import { useAppSelector, useGetLoanProductListQuery, useGetUserQuery } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  CommonOptionProps,
  Form,
  RadioGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { Spinner } from '@harmoney/ui-design-system';
import { kebabCase } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../common-props';

export function ProductSelection({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const branch = useBranch();

  const token = useAppSelector((state) => state.accessToken.value);
  const { data: products, isLoading: isProductsLoading } = useGetLoanProductListQuery(branch, {
    skip: !branch || !token,
  });
  const { data: userData, isLoading } = useGetUserQuery(null, { skip: !token });

  const schema = z.object({
    product: z.string().min(1, {
      message: 'Please select an option',
    }),
  });

  const form = useForm({
    mode: 'onSubmit',
    schema: schema,
    defaultValues: {
      product: '',
    },
  });

  const options: CommonOptionProps[] = useMemo(
    () =>
      products &&
      products?.map((product) => {
        return {
          label: product?.name,
          value: product?.name,
          icon: `hmy:${kebabCase(product.name)}`,
          iconSelected: product.name === 'Debt consolidation' ? `hmy:${kebabCase(product.name)}-selected` : null,
        };
      }),
    [products]
  );

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const handleSubmit = (data) => {
    completeTask({ taskId, variables: { loanProductName: data?.product } });
  };

  useFriendlyURL(taskFriendlyURL);

  if (isLoading || isProductsLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <h1>
        Hi <span className="text-primary">{userData?.preferredName}</span>, tell us more
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <RadioGroup {...register('product')} label="How can we help?" options={options} />
        </Card>
        <Button
          type="submit"
          variant="primary"
          alignIcon="end"
          hasShadow
          isLoading={isSubmitting || isSubmitSuccessful}
          icon={<ArrowCircleRightIcon size="large" />}
        >
          Continue
        </Button>
      </Form>
      <div className="m-2 flex flex-row gap-2"></div>
    </div>
  );
}
