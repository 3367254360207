import { generateIcon } from '../generateIcon';

export const ArrowCircleRightRoundedIcon = generateIcon({
  viewBox: '0 0 32 32',
  path: (
    <>
      <g clipPath="url(#clip0_2930_1129)">
        <path
          d="M0 16C7.72516e-07 7.16344 7.16345 -7.72516e-07 16 0C24.8366 7.72516e-07 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 -7.72516e-07 24.8366 0 16Z"
          stroke="transparent"
          fill="#FF4B4A"
        />
        <path
          className="animate-swing"
          d="M22 15.6L16.4 10M22 15.6L16.4 21.2M22 15.6H6"
          stroke="white"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2930_1129">
          <rect width="2em" height="2em" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
