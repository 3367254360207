import { Fragment } from 'react';
import { essentialExpenseMapper, FinancialProfileDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';

export const Expenses = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Essential expenses</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.expenses.map(
        ({
          id,
          declaredAmount,
          frequency,
          otherExpenseType,
          declaredTotalAmount,
          frequencyForTotalAmount,
          networthSourceId,
          isExpenseShared,
        }) => (
          <Fragment key={`expenses-${id}`}>
            {isExpenseShared ? (
              <div className="flex flex-col p-4">
                <Label>
                  {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                    ? otherExpenseType
                    : essentialExpenseMapper[networthSourceId]}
                </Label>

                <ul className="mt-2 text-sm">
                  <li className="flex justify-between">
                    <span>Total cost</span>
                    <CurrencyWithFrequency
                      amount={declaredTotalAmount}
                      frequency={frequencyForTotalAmount}
                      className="font-medium"
                    />
                  </li>
                  <li className="flex justify-between">
                    <span>Your share</span>
                    <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
                  </li>
                </ul>
              </div>
            ) : (
              <div className="flex justify-between p-4">
                <Label>
                  {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                    ? otherExpenseType
                    : essentialExpenseMapper[networthSourceId]}
                </Label>
                <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
              </div>
            )}
            <Divider className="text-grey-1 my-0 px-4" />
          </Fragment>
        )
      )}
    </Card>
  );
};
