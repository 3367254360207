// Event names
export const CUSTOMER_ACCOUNT_SIGN_IN = 'customer_account_sign_in';
export const CUSTOMER_PROFILE_COMPLETED = 'customer_profile_completed'; //TODO not tracked

// TODO: Confirm the event name of SMART_UI_LOADED_EVENT since it is inconsistent with the other events
export const SMART_UI_LOADED_EVENT = 'Smart UI Loaded';
export const IDENTITY_VERIFICATION_COMPLETED = 'identity_verification_completed';

export const FINANCIALPROFILE_BANKPROFILE_UPLOADED = 'financialprofile_bankprofile_uploaded';
export const FINANCIALPROFILE_ASSETS_PROVIDED = 'financialprofile_assets_provided';
export const FINANCIALPROFILE_EXPENSES_PROVIDED = 'financialprofile_expenses_provided';
export const FINANCIALPROFILE_DEBTS_PROVIDED = 'financialprofile_debts_provided';
export const FINANCIALPROFILE_INCOME_PROVIDED = 'financialprofile_income_provided';
export const FINANCIALPROFILE_LIVING_SITUATION_PROVIDED = 'financialprofile_living_situation_provided';
export const FINANCIALPROFILE_HOUSEHOLD_INCOME_PROVIDED = 'financialprofile_household_income_provided';

export const LOAN_APPLICATION_SUBMITTED = 'loan_application_submitted';
export const LOAN_APPLICATION_WITHDRAWN = 'loan_application_withdrawn'; // TODO not tracked

export const LOAN_CONTRACT_ACCEPTED = 'loan_contract_accepted';
export const LOAN_PAYMENTS_ACCEPTED = 'loan_payments_accepted';

export const PROVISO_IFRAME_DISPLAYED = 'proviso_iframe_displayed';
export const PROVISO_IFRAME_LOADED = 'proviso_iframe_loaded';
export const PROVISO_IFRAME_LOGIN_COMPLETE = 'proviso_iframe_login_complete';
export const PROVISO_IFRAME_LOGIN_FAILED = 'proviso_iframe_login_failed';
export const PROVISO_IFRAME_ERROR_OCCURRED = 'proviso_iframe_error_occurred';
export const PROVISO_IFRAME_INVALID_TOKEN = 'proviso_iframe_invalid_token';

export const GETTING_TO_KNOW_YOU_PROVIDED = 'gtky_provided';
export const DEBTCON_OBJECTIVES_ACCEPTED = 'debtcon_objectives_accepted';
export const APPLICATION_APPROVED = 'application_approved';

export const NEW_SESSION = 'new_session_started';

export const QUOTE_READY = 'quote_ready';
