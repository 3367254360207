import { generateIcon } from '../generateIcon';

export const RefreshIcon = generateIcon({
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_237_5350)">
        <path
          d="M17.6498 6.34999C16.0198 4.71999 13.7098 3.77999 11.1698 4.03999C7.49978 4.40999 4.47978 7.38999 4.06978 11.06C3.51978 15.91 7.26978 20 11.9998 20C15.1898 20 17.9298 18.13 19.2098 15.44C19.5298 14.77 19.0498 14 18.3098 14C17.9398 14 17.5898 14.2 17.4298 14.53C16.2998 16.96 13.5898 18.5 10.6298 17.84C8.40978 17.35 6.61978 15.54 6.14978 13.32C5.30978 9.43999 8.25978 5.99999 11.9998 5.99999C13.6598 5.99999 15.1398 6.68999 16.2198 7.77999L14.7098 9.28999C14.0798 9.91999 14.5198 11 15.4098 11H18.9998C19.5498 11 19.9998 10.55 19.9998 9.99999V6.40999C19.9998 5.51999 18.9198 5.06999 18.2898 5.69999L17.6498 6.34999Z"
          fill="#434343"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_5350">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
