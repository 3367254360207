import React, { useMemo } from 'react';
import { NumberFormatter } from '@harmoney/ui-utils';
import { convertToMonthlyAmount, formatNumber } from '@harmoney/utilities';
import { AffordabilityModelEnum, IncomeAndExpenseFrequencyEnum, Prisma } from '@prisma/client';

type Props = {
  affordabilityData: Prisma.JsonValue;
  rootData: Prisma.JsonValue;
};

export type RowData = {
  title: string;
  final: string;
  benchmark?: string;
  declared?: string;
  bankStatement?: string;
  override?: string;
};

export const ExpensesTable = ({ affordabilityData, rootData }: Props) => {
  const formatFinalValue = (key: string): string => {
    if (rootData?.['adminPortal']?.['affordability']) {
      const selectedOffer = rootData?.['adminPortal']?.['affordability']?.['selectedOffer'];
      return NumberFormatter(
        rootData?.['adminPortal']?.['affordability']?.[
          selectedOffer === AffordabilityModelEnum.none ? AffordabilityModelEnum.hMoney : selectedOffer
        ]?.['finalExpense'][key] as number
      );
    }
    return NumberFormatter(affordabilityData?.['finalExpense']?.[key] as number);
  };

  const formatFinalExpense = (): string => {
    if (rootData?.['adminPortal']?.['affordability']) {
      const selectedOffer = rootData?.['adminPortal']?.['affordability']?.['selectedOffer'];
      return NumberFormatter(
        rootData?.['adminPortal']?.['affordability']?.[
          selectedOffer === AffordabilityModelEnum.none ? AffordabilityModelEnum.hMoney : selectedOffer
        ]?.['finalValueUsedInUMI']?.['finalTotalExpense'] as number
      );
    }
    return NumberFormatter(affordabilityData?.['finalTotalExpense'] as number);
  };

  const getOverrideExpense = (key) =>
    !isNaN(affordabilityData?.['expense']?.['coOverrideExpense']?.[key]) &&
    NumberFormatter(affordabilityData?.['expense']?.['coOverrideExpense']?.[key] as number);

  const rowsData: RowData[] = useMemo(() => {
    return [
      {
        title: 'Total Rent',
        declared: NumberFormatter(affordabilityData?.['expense']?.['declaredTotalAccommodationExpense'] as number),
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM098'] as number),
        benchmark: NumberFormatter(
          convertToMonthlyAmount(affordabilityData?.['medianRent'], IncomeAndExpenseFrequencyEnum.weekly) as number
        ),
        override: getOverrideExpense('coOverrideRentalExpense'),
        final: formatFinalValue('rentalExpense'),
      },
      {
        title: 'Shared Rent',
        declared: NumberFormatter(affordabilityData?.['expense']?.['declaredSharedAccommodationExpense'] as number),
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM098'] as number),
        benchmark: NumberFormatter(
          convertToMonthlyAmount(affordabilityData?.['medianRent'], IncomeAndExpenseFrequencyEnum.weekly) as number
        ),
        final: NumberFormatter(
          affordabilityData?.['sharedExpenseResult']?.['finalSharedAccommodationExpense'] as number
        ),
      },
      {
        title: 'Total Living Expenses',
        declared: NumberFormatter(affordabilityData?.['expense']?.['declaredTotalLivingExpense'] as number),
        bankStatement: null,
        benchmark: NumberFormatter(
          convertToMonthlyAmount(affordabilityData?.['benchmarkValue'], IncomeAndExpenseFrequencyEnum.weekly) as number
        ),
        override: getOverrideExpense('coOverrideLivingExpense'),
        final: formatFinalValue('livingExpense'),
      },
      {
        title: 'Shared Living Expenses',
        declared: NumberFormatter(affordabilityData?.['expense']?.['declaredSharedLivingExpense'] as number),
        bankStatement: null,
        benchmark: NumberFormatter(
          convertToMonthlyAmount(affordabilityData?.['benchmarkValue'], IncomeAndExpenseFrequencyEnum.weekly) as number
        ),
        final: NumberFormatter(affordabilityData?.['sharedExpenseResult']?.['finalSharedLivingExpense'] as number),
      },
      {
        title: 'Child Support',
        declared: NumberFormatter(affordabilityData?.['expense']?.['declaredChildSupportExpense'] as number),
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM104'] as number),
        benchmark: null,
        override: getOverrideExpense('coOverrideChildSupportExpense'),
        final: formatFinalValue('childSupportExpense'),
      },
      {
        title: 'Total Other',
        declared: formatNumber(affordabilityData?.['expense']?.['declaredOtherExpense'] as number),
        bankStatement: null,
        benchmark: null,
        override: getOverrideExpense('coOverrideOtherExpense'),
        final: formatFinalValue('otherExpense'),
      },
      {
        title: 'Shared Other',
        declared: formatNumber(affordabilityData?.['expense']?.['declaredSharedOtherExpense'] as number),
        bankStatement: null,
        benchmark: null,
        override: getOverrideExpense('coOverrideSharedOtherExpense'),
        final: formatNumber(affordabilityData?.['sharedExpenseResult']?.['finalSharedOtherExpense'] as number),
      },
      {
        title: 'Expenses Increases',
        declared: NumberFormatter(affordabilityData?.['expense']?.['declaredIncreasedExpense'] as number),
        bankStatement: null,
        benchmark: null,
        final: NumberFormatter(affordabilityData?.['expense']?.['declaredIncreasedExpense'] as number),
      },
    ];
  }, [affordabilityData, rootData]);
  return (
    <table className="border-grey-2 table-fixed border w-full">
      <thead>
        <tr className="divide-grey-2 bg-grey-1 divide-x">
          <th className="w-1/6">Expenses</th>
          <th className="w-1/6">Declared</th>
          <th className="w-1/6">Bank Statement</th>
          <th className="w-1/6">Benchmark/Market</th>
          <th className="w-1/6">Co-Override</th>
          <th className="w-1/6">Final</th>
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row) => (
          <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
            <td className="bg-grey-1 px-2 py-1 font-bold">{row.title}</td>
            <td className={`px-2 text-right ${row.declared ? '' : 'bg-grey-2'}`}>{row.declared ?? ''}</td>
            <td className={`px-2 text-right ${row.bankStatement ? '' : 'bg-grey-2'}`}>{row.bankStatement ?? ''}</td>
            <td className={`px-2 text-right ${row.benchmark ? '' : 'bg-grey-2'}`}>{row.benchmark ?? ''}</td>
            <td className={`px-2 text-right ${row.override ? '' : 'bg-grey-2'}`}>{row.override ?? ''}</td>
            <td className="px-2 text-right">{row.final}</td>
          </tr>
        ))}
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Expenses</td>
          <td className="px-2 text-right">{formatFinalExpense()}</td>
        </tr>
      </tbody>
    </table>
  );
};
