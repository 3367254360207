export enum LoanPurposeNameEnum {
  DEBT_CONSOLIDATION = 'debt consolidation',
  GROWING_MY_BUSINESS = 'growing my business',
  WEDDING = 'wedding',
  EDUCATION = 'education',
  LEGAL_FEES = 'legal fees',
  CLEARING_OVERDRAFT = 'clearing overdraft',
  TAX_BILL = 'tax bill',
  LOAN_TO_FAMILY_MEMBER = 'loan to family member',
  COMPUTER = 'computer',
  BOAT = 'boat',
  CARAVAN = 'caravan',
  SOMETHING_ELSE = 'something else',
  IMPROVING_MY_HOME = 'improving my home',
  GOING_ON_HOLIDAY = 'going on holiday',
  USED_VEHICLE = 'used vehicle',
  NEW_VEHICLE = 'new vehicle',
  HOUSEHOLD_ITEMS = 'household items',
  MEDICAL_EXPENSES = 'medical expenses',
  FUNERAL_EXPENSES = 'funeral expenses',
}

export const loanPurposeNameMapper = {
  'debt consolidation': 'Debt Consolidation',
  'growing my business': 'Business Cash Flow',
  wedding: 'Wedding Expenses',
  education: 'Education Expenses',
  'legal fees': 'Legal Fees',
  'clearing overdraft': 'Clear Overdraft',
  'tax bill': 'Tax Bill',
  'loan to family member': 'Loan to Family Member',
  computer: 'Computer',
  boat: 'Purchase Boat',
  caravan: 'Purchase Caravan',
  'something else': 'Other',
  'improving my home': 'Home Improvements',
  'going on holiday': 'Holiday Expenses',
  'used vehicle': 'Purchase Used Vehicle',
  'new vehicle': 'Purchase New Vehicle',
  'household items': 'Household Items',
  'medical expenses': 'Medical Expenses',
  'funeral expenses': 'Funeral Expenses',
};
