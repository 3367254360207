import { generateIcon } from '../generateIcon';

export const AddTaskIcon = generateIcon({
  viewBox: '0 0 53 49',
  path: (
    <>
      <path
        d="M46.5642 9.98877L22.8142 33.7388C21.8879 34.665 20.3917 34.665 19.4654 33.7388L12.7442 27.0175C11.8179 26.0913 11.8179 24.595 12.7442 23.6688C13.6704 22.7425 15.1667 22.7425 16.0929 23.6688L21.1279 28.7038L43.1917 6.64002C44.1179 5.71377 45.6142 5.71377 46.5404 6.64002C47.4904 7.56627 47.4904 9.06252 46.5642 9.98877ZM24.5004 43.5C13.3142 43.5 4.36041 33.7863 5.61916 22.3625C6.54541 14.0025 13.0292 7.13877 21.3179 5.76127C25.6167 5.04877 29.7017 5.80877 33.1692 7.61377C34.0954 8.08877 35.2117 7.92252 35.9479 7.18627C37.0879 6.04627 36.8029 4.12252 35.3779 3.38627C31.8867 1.60502 27.9442 0.631272 23.7404 0.750022C11.5329 1.13002 1.39166 11.0575 0.774164 23.2413C0.0854137 36.945 10.9629 48.25 24.5004 48.25C27.3504 48.25 30.0579 47.7513 32.5992 46.825C34.2142 46.2313 34.6654 44.1413 33.4304 42.9063C32.7892 42.265 31.8154 42.0275 30.9604 42.36C28.9417 43.0963 26.7567 43.5 24.5004 43.5ZM41.1254 31.625H36.3754C35.0692 31.625 34.0004 32.6938 34.0004 34C34.0004 35.3063 35.0692 36.375 36.3754 36.375H41.1254V41.125C41.1254 42.4313 42.1942 43.5 43.5004 43.5C44.8067 43.5 45.8754 42.4313 45.8754 41.125V36.375H50.6254C51.9317 36.375 53.0004 35.3063 53.0004 34C53.0004 32.6938 51.9317 31.625 50.6254 31.625H45.8754V26.875C45.8754 25.5688 44.8067 24.5 43.5004 24.5C42.1942 24.5 41.1254 25.5688 41.1254 26.875V31.625Z"
        fill="url(#paint0_linear_179_29)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_179_29"
          x1="53.0004"
          y1="24.4952"
          x2="0.743164"
          y2="24.4952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4B4A" />
          <stop offset="1" stopColor="#FF8D8C" />
        </linearGradient>
      </defs>
    </>
  ),
});
