import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';

import type { PolymorphicComponent, PolymorphicProps } from '../../types';
import { Spinner } from '../Spinner';

import styles from './Button.module.scss';

export type ButtonVariant =
  | 'primary'
  | 'primary-gradient'
  | 'outline-primary'
  | 'outline-secondary'
  | 'accent'
  | 'secondary'
  | 'tertiary'
  | 'text'
  | 'link'
  | 'social';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonIconPosition = 'start' | 'end';

export type ButtonInternalProps = {
  /**
   * The element used for the root node.
   * @default "button"
   */
  as?: 'a' | 'button';
  /**
   * Determines style variation of Button component
   */
  variant?: ButtonVariant;
  /**
   * Determines size variation of Button component
   * @default large
   */
  size?: ButtonSize;
  /**
   * Disabled interaction and applies disabled styles
   * @default false
   */
  disabled?: boolean;
  /**
   * Adds loading indicator icon and disables interactions
   * @default false
   */
  isLoading?: boolean;
  /**
   * Forces button to take 100% of the container
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * Adds icon to the button. Expects any of the icon components
   */
  icon?: React.ReactElement;
  /**
   * Aligns icon to the start or end of the button
   * @default end
   */
  alignIcon?: ButtonIconPosition;
  children?: React.ReactNode;
  /**
   * Determines if to show shadow, common rules, a button in a card component should not have shadow, a button outside a card component should have shadow
   * @default false
   */
  hasShadow?: boolean;
};

export type ButtonProps<E extends React.ElementType = 'button'> = PolymorphicProps<ButtonInternalProps, E, 'disabled'>;

const LOADING_TIME_THRESHOLD_MS = +process.env['NEXT_PUBLIC_LOAD_TIME_THRESHOLD_IN_MILLISECONDS']! || 5000;

const _Button = <E extends React.ElementType = 'button'>(
  {
    as,
    variant,
    size = 'large',
    disabled = false,
    isLoading,
    isFullWidth,
    icon,
    alignIcon = 'end',
    children,
    className,
    onClick,
    hasShadow = false,
    ...restProps
  }: ButtonProps<E>,
  ref: React.Ref<any>
) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (isLoading) {
      timeoutId = setTimeout(() => {
        Sentry.captureEvent({
          message: `Button loading exceeds ${LOADING_TIME_THRESHOLD_MS / 1000}s`,
          level: 'warning',
          tags: { component: 'Button', action: 'click', page: window.location.href },
          extra: {
            page: window.location.href,
          },
        });
      }, LOADING_TIME_THRESHOLD_MS);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    if (isLoading || disabled) {
      e.preventDefault();
      return;
    }
    (onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(e);
  };

  const iconContent = () => {
    if (isLoading) {
      icon = <Spinner variant="tertiary" size="small" />;
    }

    if (disabled && icon && alignIcon === 'end') {
      icon = undefined;
    }

    if (!icon) return null;

    if (variant === 'social')
      return (
        <span
          className={
            alignIcon === 'start' ? classNames(styles['btn-social-start']) : classNames(styles['btn-social-end'])
          }
        >
          {icon}
        </span>
      );

    const iconStyle = classNames(styles[alignIcon]);

    return <span className={iconStyle}>{icon}</span>;
  };

  const classes = classNames(
    [styles['btn']],
    {
      [styles[`btn-${variant}`]]: variant,
      [styles[`btn-${size}`]]: size,
      [styles[`btn-disabled`]]: disabled,
      [styles[`btn-loading`]]: iconContent() && isLoading,
      [styles[`btn-wide`]]: isFullWidth,
      [styles[`btn-icon-only`]]: !children && children !== 0 && iconContent(),
      [styles[`btn-icon`]]: children && iconContent(),
      [styles[`btn-icon-${alignIcon}`]]: iconContent() && alignIcon,
      [styles[`btn-shadow`]]: hasShadow,
    },
    className
  );

  const commonProps = {
    className: classes,
    ref,
    onClick: handleClick,
  };

  const commonContent = (
    <>
      {icon && alignIcon === 'start' && iconContent()}
      {children && <span>{children}</span>}
      {icon && alignIcon === 'end' && iconContent()}
    </>
  );

  if (as === 'a') {
    return (
      <a {...restProps} {...commonProps}>
        {commonContent}
      </a>
    );
  }

  if (iconContent() && !children && children !== 0) {
    return (
      <button type="button" disabled={disabled} {...restProps} {...commonProps}>
        {iconContent()}
      </button>
    );
  }

  return (
    <button type="button" disabled={disabled} {...restProps} {...commonProps}>
      {commonContent}
    </button>
  );
};

export const Button = React.forwardRef(_Button) as PolymorphicComponent<ButtonInternalProps, 'button', 'disabled'>;
