import { generateIcon } from '../generateIcon';

export const VerifyIcon = generateIcon({
  viewBox: '0 0 24 24',
  variant: 'none',
  path: (
    <>
      <g clipPath="url(#clip0_23_1404)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 1H14V3H2V17H0V3C0 1.9 0.9 1 2 1ZM6 5H17C18.1 5 19 5.9 19 7V8.08296C18.6748 8.0284 18.3407 8 18 8C17.6593 8 17.3252 8.0284 17 8.08296V7H6V21H17V19.917C17.3252 19.9716 17.6593 20 18 20C18.3407 20 18.6748 19.9716 19 19.917V21C19 22.1 18.1 23 17 23H6C4.9 23 4 22.1 4 21V7C4 5.9 4.9 5 6 5Z"
          fill="#73A4F5"
        />
        <path
          d="M16.8225 15.2425L14.7025 13.1225C14.3125 12.7325 13.6825 12.7325 13.2925 13.1225C12.9025 13.5125 12.9025 14.1425 13.2925 14.5325L16.1225 17.3625C16.5125 17.7525 17.1425 17.7525 17.5325 17.3625L23.1925 11.7025C23.5825 11.3125 23.5825 10.6825 23.1925 10.2925C22.8025 9.9025 22.1725 9.9025 21.7825 10.2925L16.8225 15.2425Z"
          fill="#206EEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1404">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
