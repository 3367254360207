import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { Badge, Card, CurrencyWithFrequency, Tooltip } from '@harmoney/ui-design-system';
import { booleanToYesNo, valueOrNA } from '@harmoney/ui-utils';
import { formatCurrency, isDebtCon } from '@harmoney/utilities';
import { AssetAndLiability, NetworthSource, Prisma } from '@prisma/client';
import classNames from 'classnames';
import { isBoolean } from 'lodash';

import { Field } from '../shared';

interface DebtDetailsProps {
  debtData: (AssetAndLiability & {
    networthSource: NetworthSource;
  })[];
  loanProduct?: string;
  rootData: Prisma.JsonValue;
}

export const DebtDetails = ({ debtData, loanProduct, rootData }: DebtDetailsProps) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      {[...debtData]
        ?.sort((a, b) => {
          if (a.paidOff && !b.paidOff) return 1;
          if (!a.paidOff && b.paidOff) return -1;
          return 0;
        })
        .map((debt) => {
          return (
            <Card
              key={debt.id}
              className={classNames('shadow-none border border-grey-2 h-fit !py-4 !px-8 space-y-2 !mb-0')}
            >
              <div className="flex flex-row gap-2 flex-wrap">
                {isDebtCon(loanProduct) && debt.debtConsolidation && (
                  <Badge label="Customer selected debt" variant="secondary" />
                )}
                {isDebtCon(loanProduct) && debt.postApprovalDebtConsolidation && (
                  <Badge label="Approved for Debt Consolidation" variant="secondary" />
                )}
                {debt.creditFileReference && <Badge label="From Credit File" variant="tertiary" />}
                {debt.paidOff && <Badge label="Paid Off" variant="tertiary" />}
              </div>
              <Field>
                <span>Debt type:</span>
                <span className="font-medium capitalize">{valueOrNA(debt?.networthSource.name)}</span>
              </Field>
              {debt.networthSourceId !== NetworthSourceEnum.LIABILITY_NO_ID && (
                <>
                  {debt?.debtType && (
                    <Field>
                      <span>Other debt type:</span>
                      <span>{debt?.debtType}</span>
                    </Field>
                  )}
                  {debt?.provider && (
                    <Field>
                      <span>Provider:</span>
                      <span className="uppercase">{valueOrNA(debt?.provider)}</span>
                    </Field>
                  )}
                  {debt?.otherProvider && (
                    <Field>
                      <span className="uppercase">Other Provider:</span>
                      <span>{valueOrNA(debt?.otherProvider)}</span>
                    </Field>
                  )}
                  {debt?.repaymentAmount && (
                    <Field>
                      <span>Total repayment:</span>
                      <span>
                        <CurrencyWithFrequency amount={debt.repaymentAmount} frequency={debt.frequency} />
                      </span>
                    </Field>
                  )}
                  {debt?.networthSource?.name === 'mortgage' && (
                    <>
                      {isBoolean(debt?.isResidence) && (
                        <Field>
                          <span>Residing on the property:</span>
                          <span>{booleanToYesNo(debt?.isResidence)}</span>
                        </Field>
                      )}
                      {isBoolean(debt?.rentalIncomeCoversMortgage) && (
                        <Field>
                          <span>Rental income:</span>
                          <span>
                            {debt?.rentalIncomeCoversMortgage ? (
                              <>
                                <span className="mr-2">Fully covers the mortgage</span>
                                <span className="align-middle inline-block">
                                  <Tooltip>
                                    Total mortgage repayment can be <br />
                                    excluded from UMI.
                                  </Tooltip>
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="mr-2">Does not fully cover the mortgage</span>
                                <span className="align-middle inline-block">
                                  <Tooltip>
                                    Total mortgage repayment will be <br />
                                    included in UMI.
                                  </Tooltip>
                                </span>
                              </>
                            )}
                          </span>
                        </Field>
                      )}
                      {isBoolean(debt?.isJointMortgage) && (
                        <Field>
                          <span>Shares mortgage repayment:</span>
                          <span>{booleanToYesNo(debt?.isJointMortgage)}</span>
                        </Field>
                      )}
                      {isBoolean(debt?.isMortgageShared) && (
                        <Field>
                          <span>Shares mortgage repayment:</span>
                          <span>{booleanToYesNo(debt?.isMortgageShared)}</span>
                        </Field>
                      )}
                      {debt?.jointMortgageAmount && (
                        <Field>
                          <span>Mortgage contribution:</span>
                          {debt?.jointMortgageAmount > 0 ? (
                            <CurrencyWithFrequency
                              amount={debt?.jointMortgageAmount}
                              frequency={debt?.jointMortgageFrequency}
                            />
                          ) : (
                            `$0`
                          )}
                        </Field>
                      )}
                      {(rootData?.['sharedExpenseResultForHLoan']?.['sharedMortgageExpenseEligibility'] ||
                        rootData?.['sharedExpenseResultForHMoney']?.['sharedMortgageExpenseEligibility']) && (
                        <Field>
                          <span>Applied Shared Mortgage:</span>
                          <span>
                            {booleanToYesNo(
                              rootData?.['sharedExpenseResultForHLoan']?.['sharedMortgageExpenseEligibility']?.[
                                'isEligible'
                              ] ||
                                rootData?.['sharedExpenseResultForHMoney']?.['sharedMortgageExpenseEligibility']?.[
                                  'isEligible'
                                ]
                            )}
                          </span>
                        </Field>
                      )}
                    </>
                  )}
                  {debt?.creditLimit && (
                    <Field>
                      <span>Credit limit:</span>
                      <span>{formatCurrency(debt?.creditLimit)}</span>
                    </Field>
                  )}
                  {debt?.outstandingBalance && (
                    <Field>
                      <span>Outstanding Balance:</span>
                      <span>{formatCurrency(debt?.outstandingBalance)}</span>
                    </Field>
                  )}
                  {debt?.interestRate && (
                    <Field>
                      <span>Interest rate:</span>
                      <span>{`${debt?.interestRate}%`}</span>
                    </Field>
                  )}
                  {debt?.paysOutstandingBalance && (
                    <Field>
                      <span>Do you pay outstanding at the end of every month?</span>
                      <span>{debt?.paysOutstandingBalance}</span>
                    </Field>
                  )}
                </>
              )}
            </Card>
          );
        })}
    </div>
  );
};
