import { ArrowCircleRightIcon, Button } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

import styles from './CheckProfile.module.scss';

/* eslint-disable-next-line */
export interface CheckProfileProps extends CommonProps {}

export function CheckProfile({ taskId, completeTask }: CheckProfileProps) {
  const handleSubmit = () => {
    completeTask({ taskId });
  };

  return (
    <div className={styles['container']}>
      <h1>Welcome to CheckProfile!</h1>
      <p>Email Validation</p>
      <p>Password</p>
      <Button
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        variant="primary"
        onClick={handleSubmit}
        hasShadow
      >
        Continue
      </Button>
    </div>
  );
}

export default CheckProfile;
