import { generateIcon } from '../generateIcon';

export const MenuIcon = generateIcon({
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        d="M47 24C47 36.7025 36.7025 47 24 47C11.2975 47 1 36.7025 1 24C1 11.2975 11.2975 1 24 1C36.7025 1 47 11.2975 47 24Z"
        fill="none"
        stroke="#FF4B4A"
        strokeWidth="2"
      />
      <path d="M13.1992 24H34.7992" stroke="#FF4B4A" strokeWidth="2.88" strokeLinecap="round" />
      <path d="M13.1992 31.2H34.7992" stroke="#FF4B4A" strokeWidth="2.88" strokeLinecap="round" />
      <path d="M13.1992 16.8H34.7992" stroke="#FF4B4A" strokeWidth="2.88" strokeLinecap="round" />
    </>
  ),
});
